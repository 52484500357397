export const ImageData = [
    // officialinterview
        {image: "https://i.imgur.com/cN9KrBt.jpg", name: "officialinterview", chara: "npc", category: "single"},
    // trainingwolf
        {image: "https://i.imgur.com/YZEhmmP.jpg", name: "trainingwolf", chara: "npc", category: "single"},
        {image: "https://i.imgur.com/lmhN1AS.jpg", name: "trainingwolf", chara: "npc", category: "single"},
    // troubleshooter
        {image: "https://i.imgur.com/2NIJfHU.jpg", name: "troubleshooter", chara: "npc", category: "single"},
    // private_date
        {image: "https://i.imgur.com/Q1OceSw.jpg", name: "private_date", chara: "npc", category: "single"},
    // shining star
        {image: "https://imgur.com/ybRc3gF.jpg", name: "shining star", chara: "seha", category: "unique"},
        {image: "https://imgur.com/7zrSDrD.jpg", name: "shining star", chara: "seulbi", category: "unique"},
        {image: "https://imgur.com/V3Ndo9D.jpg", name: "shining star", chara: "yuri", category: "unique"},
        {image: "https://imgur.com/sUru6bU.jpg", name: "shining star", chara: "j", category: "unique"},
        {image: "https://imgur.com/gGYAPXP.jpg", name: "shining star", chara: "tein", category: "unique"},
        {image: "https://imgur.com/JVj8HT1.jpg", name: "shining star", chara: "nata", category: "unique"},
        {image: "https://imgur.com/F1OLnZ3.jpg", name: "shining star", chara: "levia", category: "unique"},
        {image: "https://imgur.com/uHFvjbu.jpg", name: "shining star", chara: "harpy", category: "unique"},
        {image: "https://imgur.com/uadHAzR.jpg", name: "shining star", chara: "tina", category: "unique"},
        {image: "https://imgur.com/eH9XnM2.jpg", name: "shining star", chara: "violet", category: "unique"},
        {image: "https://imgur.com/FsDAGM0.jpg", name: "shining star", chara: "wolfgang", category: "unique"},
        {image: "https://imgur.com/v3N7ma6.jpg", name: "shining star", chara: "luna", category: "unique"},
        {image: "https://imgur.com/nXFivZj.jpg", name: "shining star", chara: "soma", category: "unique"},
        {image: "https://imgur.com/RIBqL3m.jpg", name: "shining star", chara: "bai", category: "unique"},
        {image: "https://imgur.com/ZE9BsOr.jpg", name: "shining star", chara: "seth", category: "unique"},
        {image: "https://imgur.com/a6ZnzE8.jpg", name: "shining star", chara: "chulsoo", category: "unique"},
        {image: "https://imgur.com/tz9P3fM.jpg", name: "shining star", chara: "mirae", category: "unique"},
        {image: "https://imgur.com/EZM777j.jpg", name: "shining star", chara: "eunha", category: "unique"},
        {image: "https://imgur.com/CZiVXvX.jpg", name: "shining star", chara: "lucy", category: "unique"},
        {image: "https://imgur.com/aSyBm8d.jpg", name: "shining star", chara: "aeri", category: "unique"},
    // solomon
        {image: "https://i.imgur.com/glGIb38.jpg", name: "solomon", chara: "seha", category: "unique"},
        {image: "https://i.imgur.com/0UIwMpa.jpg", name: "solomon", chara: "seulbi", category: "unique"},
        {image: "https://i.imgur.com/JVbZzNN.jpg", name: "solomon", chara: "yuri", category: "unique"},
        {image: "https://i.imgur.com/Lkhom1H.jpg", name: "solomon", chara: "j", category: "unique"},
        {image: "https://i.imgur.com/9B1FSn0.jpg", name: "solomon", chara: "tein", category: "unique"},
        {image: "https://i.imgur.com/l6NutGT.jpg", name: "solomon", chara: "nata", category: "unique"},
        {image: "https://i.imgur.com/As6AQVH.jpg", name: "solomon", chara: "levia", category: "unique"},
        {image: "https://i.imgur.com/HpnnQMi.jpg", name: "solomon", chara: "harpy", category: "unique"},
        {image: "https://i.imgur.com/050jYIP.jpg", name: "solomon", chara: "tina", category: "unique"},
        {image: "https://i.imgur.com/fFksfGB.jpg", name: "solomon", chara: "violet", category: "unique"},
        {image: 'https://i.imgur.com/3dhcVor.jpg', name: 'solomon', chara: 'wolfgang', category: 'unique'},
        {image: 'https://i.imgur.com/vYe7nbe.jpg', name: 'solomon', chara: 'luna', category: 'unique'},
        {image: 'https://i.imgur.com/AYaZS34.jpg', name: 'solomon', chara: 'soma', category: 'unique'},
        {image: 'https://i.imgur.com/0cm0Sin.jpg', name: 'solomon', chara: 'bai', category: 'unique'},
        {image: 'https://i.imgur.com/wyHzAzL.jpg', name: 'solomon', chara: 'seth', category: 'unique'},
        {image: 'https://i.imgur.com/ItK0ccN.jpg', name: 'solomon', chara: 'mirae', category: 'unique'},
        {image: 'https://i.imgur.com/y6Rb1Sm.jpg', name: 'solomon', chara: 'chulsoo', category: 'unique'},
        {image: 'https://i.imgur.com/vQO5JRU.jpg', name: 'solomon', chara: 'eunha', category: 'unique'},
        {image: 'https://i.imgur.com/a4VdXcy.jpg', name: 'solomon', chara: 'lucy', category: 'unique'},
        {image: 'https://i.imgur.com/ZguYone.png', name: 'solomon', chara: 'aeri', category: 'unique'},
        {image: 'https://i.imgur.com/XEqnOIa.png', name: 'solomon', chara: 'ria', category: 'unique'},
    // wot
        {image: "https://i.imgur.com/fHlwcZG.png", name: "wot", chara: "seha", category: "unique"},
        {image: "https://i.imgur.com/7kp11MU.png", name: "wot", chara: "seulbi", category: "unique"},
        {image: "https://i.imgur.com/pqNNn4E.png", name: "wot", chara: "yuri", category: "unique"},
        {image: "https://i.imgur.com/XIvo2Fe.png", name: "wot", chara: "j", category: "unique"},
        {image: "https://i.imgur.com/mH5tteY.png", name: "wot", chara: "tein", category: "unique"},
        {image: "https://i.imgur.com/ObLCAZX.png", name: "wot", chara: "nata", category: "unique"},
        {image: "https://i.imgur.com/Q9sgJ0f.png", name: "wot", chara: "levia", category: "unique"},
        {image: "https://i.imgur.com/UzOrR4w.png", name: "wot", chara: "harpy", category: "unique"},
        {image: "https://i.imgur.com/xBqg2V8.png", name: "wot", chara: "tina", category: "unique"},
        {image: "https://i.imgur.com/2yy8b4q.png", name: "wot", chara: "violet", category: "unique"},
        {image: "https://i.imgur.com/CrUn7LU.jpg", name: "wot", chara: "wolfgang", category: "unique"},
        {image: "https://i.imgur.com/hahmRRu.jpg", name: "wot", chara: "luna", category: "unique"},
        {image: "https://i.imgur.com/FnCPWLe.jpg", name: "wot", chara: "soma", category: "unique"},
        {image: "https://i.imgur.com/VpnGcC4.jpg", name: "wot", chara: "bai", category: "unique"},
        {image: "https://i.imgur.com/UONcqvP.jpg", name: "wot", chara: "seth", category: "unique"},
        {image: "https://i.imgur.com/fnWmuRM.png", name: "wot", chara: "mirae", category: "unique"},
        {image: "https://i.imgur.com/ZenFLXg.png", name: "wot", chara: "chulsoo", category: "unique"},
        {image: "https://i.imgur.com/E1olE9C.png", name: "wot", chara: "eunha", category: "unique"},
        {image: "https://i.imgur.com/iHPggVo.png", name: "wot", chara: "lucy", category: "unique"},
        {image: "https://i.imgur.com/HqGkaco.png", name: "wot", chara: "aeri", category: "unique"},
    // sod
        {image: "https://i.imgur.com/MkvaUtQ.jpg", name: "sod", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/KiIaAgc.jpg", name: "sod", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/Uf7XD4F.jpg", name: "sod", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/gvKYQXH.jpg", name: "sod", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/zwwYyOU.jpg", name: "sod", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/zH0Www3.jpg", name: "sod", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/feJABYp.jpg", name: "sod", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/bnY4Ahd.jpg", name: "sod", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/Esdbxzq.jpg", name: "sod", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/m9z97gF.jpg", name: "sod", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/Rx9fIo3.jpg", name: "sod", chara: "wolfgang", category: "rare"},
        {image: "https://i.imgur.com/XmSXVpD.jpg", name: "sod", chara: "luna", category: "rare"},
        {image: "https://i.imgur.com/DNd05Fn.jpg", name: "sod", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/99vWB0m.jpg", name: "sod", chara: "bai", category: "rare"},
        {image: "https://i.imgur.com/RRI1yIy.jpg", name: "sod", chara: "seth", category: "rare"},
        {image: "https://i.imgur.com/3P2BfY1.jpg", name: "sod", chara: "mirae", category: "rare"},
        {image: "https://i.imgur.com/L8cszL2.jpg", name: "sod", chara: "chulsoo", category: "rare"},
        {image: "https://i.imgur.com/CeNL3sa.jpg", name: "sod", chara: "eunha", category: "rare"},
        {image: "https://i.imgur.com/rUrhbQE.jpg", name: "sod", chara: "lucy", category: "rare"},
        {image: "https://imgur.com/vxKbJpA.jpg", name: "sod", chara: "aeri", category: "rare"},
    // cybernetics
        {image: "https://i.imgur.com/mqCNBg6.jpg", name: "cybernetics", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/bTfOnxN.jpg", name: "cybernetics", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/lCryHwS.jpg", name: "cybernetics", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/cVJwmMz.jpg", name: "cybernetics", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/A3RsqtB.jpg", name: "cybernetics", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/nc7tMmL.jpg", name: "cybernetics", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/xX4B8pp.jpg", name: "cybernetics", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/KNOCO0O.jpg", name: "cybernetics", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/UgfI1KM.jpg", name: "cybernetics", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/39l9USu.jpg", name: "cybernetics", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/osAepEA.jpg", name: "cybernetics", chara: "wolfgang", category: "rare"},
    // zw
        {image: "https://i.imgur.com/GPUFxGy.jpg", name: "zw", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/XbIDhvM.jpg", name: "zw", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/HZDaup7.jpg", name: "zw", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/OtJbyZV.jpg", name: "zw", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/OdUy96P.jpg", name: "zw", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/bcE5BN0.jpg", name: "zw", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/Cxjcw2c.jpg", name: "zw", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/aiqjKZl.jpg", name: "zw", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/PEUmkoP.jpg", name: "zw", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/6sliBsf.jpg", name: "zw", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/1HBynJ4.jpg", name: "zw", chara: "wolfgang", category: "rare"},
        {image: "https://i.imgur.com/STEMWJ6.jpg", name: "zw", chara: "luna", category: "rare"},
        {image: "https://i.imgur.com/pJ0yX3v.jpg", name: "zw", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/mERhXy9.jpg", name: "zw", chara: "bai", category: "rare"},
        {image: "https://i.imgur.com/sCUzQxn.jpg", name: "zw", chara: "seth", category: "rare"},
        {image: "https://i.imgur.com/0mNk0Q7.jpg", name: "zw", chara: "mirae", category: "rare"},
        {image: "https://i.imgur.com/VE4zBuS.jpg", name: "zw", chara: "chulsoo", category: "rare"},
        {image: "https://i.imgur.com/ZXP4yei.jpg", name: "zw", chara: "eunha", category: "rare"},
        {image: "https://i.imgur.com/ZjA3xTb.jpg", name: "zw", chara: "lucy", category: "rare"},
        {image: "https://i.imgur.com/yabZMca.jpg", name: "zw", chara: "aeri", category: "rare"},
        {image: "https://i.imgur.com/IQ4olJS.png", name: "zw", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/ZtbXPKo.png", name: "zw", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/wwggW0X.jpg", name: "zw", chara: "ria", category: "rare"},
    // bw
        {image: "https://i.imgur.com/w5A5xI5.jpg", name: "bw", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/tonwckE.jpg", name: "bw", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/6BK6dZw.jpg", name: "bw", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/UEkl7KS.jpg", name: "bw", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/XVGZjob.jpg", name: "bw", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/PjPYAAE.jpg", name: "bw", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/s7gacIh.jpg", name: "bw", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/rlCjtnp.jpg", name: "bw", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/QkDC97A.jpg", name: "bw", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/ZjW7bC1.jpg", name: "bw", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/voA7qX7.jpg", name: "bw", chara: "wolfgang", category: "rare"},
        {image: "https://i.imgur.com/unKY6zt.jpg", name: "bw", chara: "luna", category: "rare"},
        {image: "https://i.imgur.com/3rZtPxu.jpg", name: "bw", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/A2ioArk.jpg", name: "bw", chara: "bai", category: "rare"},
        {image: "https://i.imgur.com/tsobH5V.jpg", name: "bw", chara: "seth", category: "rare"},
        {image: "https://i.imgur.com/DQ5Kz5p.jpg", name: "bw", chara: "mirae", category: "rare"},
        {image: "https://i.imgur.com/4S6hbzI.jpg", name: "bw", chara: "chulsoo", category: "rare"},
        {image: "https://i.imgur.com/4EcX6f1.jpg", name: "bw", chara: "eunha", category: "rare"},
        {image: "https://i.imgur.com/MbZAPS9.jpg", name: "bw", chara: "lucy", category: "rare"},
        {image: "https://i.imgur.com/2wZlrxt.jpg", name: "bw", chara: "aeri", category: "rare"},
    // dragonian
        {image: "https://i.imgur.com/7GGfYIF.jpg", name: "dragonian", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/n016QSF.jpg", name: "dragonian", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/PxWeYum.jpg", name: "dragonian", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/CbmYmmD.jpg", name: "dragonian", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/It2Jwko.jpg", name: "dragonian", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/doGG3ot.jpg", name: "dragonian", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/dNPoBuo.jpg", name: "dragonian", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/gzaRBqq.jpg", name: "dragonian", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/5GEZIKD.jpg", name: "dragonian", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/sbNEOsF.jpg", name: "dragonian", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/MbaW7ct.jpg", name: "dragonian", chara: "wolfgang", category: "rare"},
        {image: "https://i.imgur.com/liMkzEW.jpg", name: "dragonian", chara: "luna", category: "rare"},
        {image: "https://i.imgur.com/mKX6e5H.jpg", name: "dragonian", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/efwaS2G.jpg", name: "dragonian", chara: "bai", category: "rare"},
        {image: "https://i.imgur.com/s6UdIU0.jpg", name: "dragonian", chara: "seth", category: "rare"},
        {image: "https://i.imgur.com/vrjuMJ5.jpg", name: "dragonian", chara: "mirae", category: "rare"},
        {image: "https://i.imgur.com/SvVnu6r.jpg", name: "dragonian", chara: "chulsoo", category: "rare"},
        {image: "https://i.imgur.com/ieuHA0B.jpg", name: "dragonian", chara: "eunha", category: "rare"},
        {image: "https://i.imgur.com/kElvKfT.jpg", name: "dragonian", chara: "lucy", category: "rare"},
        {image: "https://i.imgur.com/HHkQcPq.jpg", name: "dragonian", chara: "aeri", category: "rare"},
        {image: "https://i.imgur.com/wsqexG5.jpg", name: "dragonian", chara: "seha", category: "rare"},
        {image: "https://i.imgur.com/HbfT6wV.jpg", name: "dragonian", chara: "seulbi", category: "rare"},
        {image: "https://i.imgur.com/xfpzG8B.jpg", name: "dragonian", chara: "yuri", category: "rare"},
        {image: "https://i.imgur.com/VnAYpHr.jpg", name: "dragonian", chara: "j", category: "rare"},
        {image: "https://i.imgur.com/AkhKg5v.jpg", name: "dragonian", chara: "tein", category: "rare"},
        {image: "https://i.imgur.com/u4cON9v.jpg", name: "dragonian", chara: "nata", category: "rare"},
        {image: "https://i.imgur.com/FEcZw7S.jpg", name: "dragonian", chara: "levia", category: "rare"},
        {image: "https://i.imgur.com/hUKiCkz.jpg", name: "dragonian", chara: "harpy", category: "rare"},
        {image: "https://i.imgur.com/cPJqXe8.jpg", name: "dragonian", chara: "tina", category: "rare"},
        {image: "https://i.imgur.com/fx3wq5a.jpg", name: "dragonian", chara: "violet", category: "rare"},
        {image: "https://i.imgur.com/cyZlxgi.jpg", name: "dragonian", chara: "wolfgang", category: "rare"},
        {image: "https://i.imgur.com/qSCYxxn.jpg", name: "dragonian", chara: "luna", category: "rare"},
        {image: "https://i.imgur.com/n55UeiB.jpg", name: "dragonian", chara: "soma", category: "rare"},
        {image: "https://i.imgur.com/LrN8F27.jpg", name: "dragonian", chara: "bai", category: "rare"},
        {image: "https://i.imgur.com/6xfrGFd.jpg", name: "dragonian", chara: "seth", category: "rare"},
        {image: "https://i.imgur.com/U12NrIy.jpg", name: "dragonian", chara: "mirae", category: "rare"},
        {image: "https://i.imgur.com/pQKqn8C.jpg", name: "dragonian", chara: "chulsoo", category: "rare"},
        {image: "https://i.imgur.com/kPdxmyC.jpg", name: "dragonian", chara: "eunha", category: "rare"},
        {image: "https://i.imgur.com/IwoBJ7r.jpg", name: "dragonian", chara: "lucy", category: "rare"},
        {image: "https://i.imgur.com/rA79sZP.jpg", name: "dragonian", chara: "aeri", category: "rare"},
    // alice-in-wonderland
        {image: "https://i.imgur.com/JlbLwCB.jpg", name: "alice-in-wonderland", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/I54zgNN.jpg", name: "alice-in-wonderland", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/dLYAXDI.jpg", name: "alice-in-wonderland", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/wPvcKyJ.jpg", name: "alice-in-wonderland", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/8zFCQUV.jpg", name: "alice-in-wonderland", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/uiraQP3.jpg", name: "alice-in-wonderland", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Mf5GNjB.jpg", name: "alice-in-wonderland", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/A28RBsN.jpg", name: "alice-in-wonderland", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/0uXTP3H.jpg", name: "alice-in-wonderland", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Fm9iDzb.jpg", name: "alice-in-wonderland", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Ch00GxS.jpg", name: "alice-in-wonderland", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/MBOTnuJ.jpg", name: "alice-in-wonderland", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/xMezqKP.jpg", name: "alice-in-wonderland", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/T7z4Jlz.jpg", name: "alice-in-wonderland", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/PeWwODC.jpg", name: "alice-in-wonderland", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/GDoV0sm.jpg", name: "alice-in-wonderland", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/SwGw5LK.jpg", name: "alice-in-wonderland", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/g29bt9B.jpg", name: "alice-in-wonderland", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/SAoSm7F.jpg", name: "alice-in-wonderland", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/nmkQMFj.jpg", name: "alice-in-wonderland", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/6wATbAu.jpg", name: "alice-in-wonderland", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/yeEPBPj.jpg", name: "alice-in-wonderland", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/cCy9HPR.jpg", name: "alice-in-wonderland", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/OZa1QUy.jpg", name: "alice-in-wonderland", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/NOseOHw.jpg", name: "alice-in-wonderland", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/gs0UKla.jpg", name: "alice-in-wonderland", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/H4O1BYc.jpg", name: "alice-in-wonderland", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/ZZuSQwb.jpg", name: "alice-in-wonderland", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/XUheAGw.jpg", name: "alice-in-wonderland", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/X5ulbvs.jpg", name: "alice-in-wonderland", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/AXuBzID.jpg", name: "alice-in-wonderland", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/XiFLTtS.jpg", name: "alice-in-wonderland", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/2vpGejd.jpg", name: "alice-in-wonderland", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/C3fAOlD.jpg", name: "alice-in-wonderland", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/FaRAp9X.jpg", name: "alice-in-wonderland", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/EoiZklp.jpg", name: "alice-in-wonderland", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/cuzFJRL.jpg", name: "alice-in-wonderland", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/TMKfLJj.jpg", name: "alice-in-wonderland", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/RILubKe.jpg", name: "alice-in-wonderland", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/q7yPQms.jpg", name: "alice-in-wonderland", chara: "aeri", category: "normal"},
    // april_cat_maid
        {image: "https://i.imgur.com/s2gZP1N.jpg", name: "april_cat_maid", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/XO5qlQU.jpg", name: "april_cat_maid", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/QwocRlu.jpg", name: "april_cat_maid", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/8XYoJ5Q.jpg", name: "april_cat_maid", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/sJIw843.jpg", name: "april_cat_maid", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UdYh0Ps.jpg", name: "april_cat_maid", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/zQkFeUS.jpg", name: "april_cat_maid", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/IqYncy2.jpg", name: "april_cat_maid", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ildSKXn.jpg", name: "april_cat_maid", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/okD9tC3.jpg", name: "april_cat_maid", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/sUsoJug.jpg", name: "april_cat_maid", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/R9u36W0.jpg", name: "april_cat_maid", chara: "eunha", category: "normal"},
    // arcade
        {image: "https://i.imgur.com/MsdiuYw.jpg", name: "arcade", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/iEyE7Sw.jpg", name: "arcade", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/hImiqqM.jpg", name: "arcade", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Y5f6q26.jpg", name: "arcade", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/cSPBZXH.jpg", name: "arcade", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/pfOb8dB.jpg", name: "arcade", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/85Vw87W.jpg", name: "arcade", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/LfkE1BE.jpg", name: "arcade", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/vg49lGD.jpg", name: "arcade", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/bZlGxhw.jpg", name: "arcade", chara: "mirae", category: "normal"},
    // band
        {image: "https://i.imgur.com/GVBdrxu.jpg", name: "band", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/GstM3YP.jpg", name: "band", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/iSOqZg6.jpg", name: "band", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/siF0UfZ.jpg", name: "band", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/fAdeCJg.jpg", name: "band", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/uwRTBkj.jpg", name: "band", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Xy851TE.jpg", name: "band", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/qCKJobQ.jpg", name: "band", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/vkkBOkC.jpg", name: "band", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/g4zYuai.jpg", name: "band", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/lDXKAVz.jpg", name: "band", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/9PoaiWJ.jpg", name: "band", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/vHrtorR.jpg", name: "band", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/peFDpkV.jpg", name: "band", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/1qGlhDy.jpg", name: "band", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/ZavE5VM.jpg", name: "band", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/8ExRcln.jpg", name: "band", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Hg1QiYm.jpg", name: "band", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/9007aCq.jpg", name: "band", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/0EDtmmq.jpg", name: "band", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/e0t9V4y.jpg", name: "band", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/hzOJRnC.jpg", name: "band", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/uIzFeir.jpg", name: "band", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/zjVahNT.jpg", name: "band", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ajZXyN3.jpg", name: "band", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/450LfaJ.jpg", name: "band", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/q2vvZKd.jpg", name: "band", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/KRA01W5.jpg", name: "band", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/lcg15uu.jpg", name: "band", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/tbls9rO.jpg", name: "band", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/veAznQa.jpg", name: "band", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/qwUAVJQ.jpg", name: "band", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/lI8k83j.jpg", name: "band", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/MysONrY.jpg", name: "band", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/yzcH43S.jpg", name: "band", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/iobnBLw.jpg", name: "band", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/kZmiaRj.jpg", name: "band", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Oixtwp0.jpg", name: "band", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/D7j2dMY.jpg", name: "band", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/nr9d5BR.jpg", name: "band", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Lww8syK.jpg", name: "band", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Fykkgca.jpg", name: "band", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/5QOVKxQ.jpg", name: "band", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/Bj5OMMG.jpg", name: "band", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/G6lqZWg.jpg", name: "band", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/OQAos0d.jpg", name: "band", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/V5PApOq.jpg", name: "band", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/BS2TIay.jpg", name: "band", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/LfiwD7m.jpg", name: "band", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/XtxNwVf.jpg", name: "band", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/xc7QqBA.jpg", name: "band", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/1NZF6nU.jpg", name: "band", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/SHTkH8V.jpg", name: "band", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Lchne3B.jpg", name: "band", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ZSdcud3.jpg", name: "band", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/t3JnDc2.jpg", name: "band", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/uQNUIJ3.jpg", name: "band", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/RFCjsE8.jpg", name: "band", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/sHAeWS6.jpg", name: "band", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/stCYmu4.jpg", name: "band", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/53oDpuo.jpg", name: "band", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/alvVtzK.jpg", name: "band", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/dWV2Af8.jpg", name: "band", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/bHZ2Ipi.jpg", name: "band", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/mBWn9yL.jpg", name: "band", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/eY5CQrX.jpg", name: "band", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/HYEICRr.jpg", name: "band", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ZihlZWV.jpg", name: "band", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/GbBRO9W.jpg", name: "band", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/XGc5mz5.jpg", name: "band", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/LilD6X9.jpg", name: "band", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/Gar5rce.jpg", name: "band", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/dX3voBP.jpg", name: "band", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/lPniFpC.jpg", name: "band", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/CcXilgr.jpg", name: "band", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/x50mTXD.jpg", name: "band", chara: "lucy", category: "normal"},
    // bittersweet
        {image: "https://i.imgur.com/EJTcvsz.jpg", name: "bittersweet", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/px2PEB2.jpg", name: "bittersweet", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/LrL8SjO.jpg", name: "bittersweet", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/PB5Covq.jpg", name: "bittersweet", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/OZcPiuV.jpg", name: "bittersweet", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/ycobjzl.jpg", name: "bittersweet", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/SaGNqmP.jpg", name: "bittersweet", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Qj8nIB4.jpg", name: "bittersweet", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/OP1YrQd.jpg", name: "bittersweet", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/SQA0q43.jpg", name: "bittersweet", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/b0Uqo23.jpg", name: "bittersweet", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/DSvsJa4.jpg", name: "bittersweet", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/Pkh4UBw.jpg", name: "bittersweet", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/NYK0Z9A.jpg", name: "bittersweet", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/s4vIvWO.jpg", name: "bittersweet", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/NRnsZFa.jpg", name: "bittersweet", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/0lZWYno.jpg", name: "bittersweet", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/hENXH4x.jpg", name: "bittersweet", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/VLoXsCI.jpg", name: "bittersweet", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/IcE7AcN.jpg", name: "bittersweet", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/HDxrwu9.jpg", name: "bittersweet", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/Iltnxqp.jpg", name: "bittersweet", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/UaVISOI.jpg", name: "bittersweet", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/uyikgQV.jpg", name: "bittersweet", chara: "lucy", category: "normal"},
    // BlackRose
        {image: "https://i.imgur.com/6pnZKtM.png", name: "blackrose", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/99KMgx2.png", name: "blackrose", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/TiE0Seo.png", name: "blackrose", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/8SJxzpq.png", name: "blackrose", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/uxMi5RX.png", name: "blackrose", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/2vCNTwo.png", name: "blackrose", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/pNAgfVG.png", name: "blackrose", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/kNKs043.png", name: "blackrose", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/yJF8soQ.png", name: "blackrose", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/MYKuGvw.png", name: "blackrose", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Q1pZ88P.png", name: "blackrose", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Dz52opQ.png", name: "blackrose", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/NMpbGqY.png", name: "blackrose", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/uG0aMZo.png", name: "blackrose", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/BI0K22F.png", name: "blackrose", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/kKRslKZ.png", name: "blackrose", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/NejSHl6.png", name: "blackrose", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/SNSn9q0.png", name: "blackrose", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/lz5Ox2L.png", name: "blackrose", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/VxbLq39.png", name: "blackrose", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/Hogz2pE.jpg", name: "blackrose", chara: "ria", category: "normal"},
        {image: "https://i.imgur.com/S4IQbXX.png", name: "blackrose", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/FMW3Q7l.png", name: "blackrose", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/renkBss.png", name: "blackrose", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/0J5rODG.png", name: "blackrose", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/NnI2T2H.png", name: "blackrose", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/FHmSgYv.png", name: "blackrose", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/KDuu5jO.png", name: "blackrose", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/sQw1TS0.png", name: "blackrose", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/MhF1nAJ.png", name: "blackrose", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/U147Mzo.png", name: "blackrose", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/UCnB17p.png", name: "blackrose", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/VT8kx0v.png", name: "blackrose", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/jWh8Fqg.png", name: "blackrose", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Oh6fyTP.png", name: "blackrose", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/FMNI11G.png", name: "blackrose", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/DlJOsZN.png", name: "blackrose", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/PJ435Sf.png", name: "blackrose", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Mu47niV.png", name: "blackrose", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/FxXOK7e.png", name: "blackrose", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/4L15OIg.png", name: "blackrose", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/KroS7HZ.jpg", name: "blackrose", chara: "ria", category: "normal"},
    // BloomingRomance
        {image: "https://i.imgur.com/fk21egV.png", name: "bloomingromance", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Y5bqjeH.png", name: "bloomingromance", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/MowIEY6.png", name: "bloomingromance", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/uaUgbto.png", name: "bloomingromance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/6SGMbKL.png", name: "bloomingromance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/7D27vk2.png", name: "bloomingromance", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Y8Sfb9n.png", name: "bloomingromance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/k3I7fvJ.png", name: "bloomingromance", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Av5stsP.png", name: "bloomingromance", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/66ZsMx8.png", name: "bloomingromance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Va2hihY.png", name: "bloomingromance", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/AnVsuEi.png", name: "bloomingromance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/a4fJspN.png", name: "bloomingromance", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ML9sxcd.png", name: "bloomingromance", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/v9kXYBO.png", name: "bloomingromance", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Cyl5NBc.png", name: "bloomingromance", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/9zrbQbb.png", name: "bloomingromance", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/leeoSNz.png", name: "bloomingromance", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/uve1Q3W.png", name: "bloomingromance", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/Nt1D8ZP.png", name: "bloomingromance", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/P2PDI12.png", name: "bloomingromance", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/vPUQVrC.png", name: "bloomingromance", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Rf31Fjx.png", name: "bloomingromance", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/v8JgITS.png", name: "bloomingromance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/OuK9KZm.png", name: "bloomingromance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ccO303U.png", name: "bloomingromance", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/mTc2AbE.png", name: "bloomingromance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/OCaQTVH.png", name: "bloomingromance", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/GjSQjJL.png", name: "bloomingromance", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/NALlsYd.png", name: "bloomingromance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/KhQFYqE.png", name: "bloomingromance", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/vP415Dr.png", name: "bloomingromance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ynVeAaI.png", name: "bloomingromance", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/u9SIaiW.png", name: "bloomingromance", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/wztfMdC.png", name: "bloomingromance", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/uiij4qq.png", name: "bloomingromance", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/u7SzTKT.png", name: "bloomingromance", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/FiIRmQV.png", name: "bloomingromance", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/OauMnfF.png", name: "bloomingromance", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/SoI7eJY.png", name: "bloomingromance", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/aTndwuy.jpg", name: "captain", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/5Ds6o2B.jpg", name: "captain", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/3IqIiSq.jpg", name: "captain", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/BRGqtU5.jpg", name: "captain", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/sRkMSSN.jpg", name: "captain", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/l2CaOXi.jpg", name: "captain", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/7IO0zjo.jpg", name: "captain", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/EgPlagK.jpg", name: "captain", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/PmSu0O2.jpg", name: "captain", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QsQjNXl.jpg", name: "captain", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/B1LeCc5.jpg", name: "captain", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/DQSTiUp.jpg", name: "captain", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/j7rMePC.jpg", name: "captain", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/0gRDRy7.jpg", name: "captain", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/crqFJBi.jpg", name: "captain", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/6IDzjXH.jpg", name: "captain", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/rGmlJnD.jpg", name: "captain", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/2UPzQoL.jpg", name: "captain", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/IEMqPWk.jpg", name: "captain", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/JVMIJ2Y.jpg", name: "captain", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/8KINYhK.jpg", name: "captain", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ERqD4hV.jpg", name: "captain", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/QWqmFgI.jpg", name: "captain", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QVA55hA.jpg", name: "captain", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/9XHIj8W.jpg", name: "captain", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ul9TS84.jpg", name: "captain", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/0f4YXJ3.jpg", name: "captain", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/jdS2KFT.jpg", name: "captain", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/EMlFow7.jpg", name: "captain", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/XcaPjnb.jpg", name: "captain", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/XEaUV5j.jpg", name: "captain", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/lZpeOzH.jpg", name: "captain", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/niNtRMU.jpg", name: "captain", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/XWizIyk.jpg", name: "captain", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/DAMEq9Z.jpg", name: "captain", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/CbOxxUo.jpg", name: "captain", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/VXzs5d8.jpg", name: "captain", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/uXdW88j.jpg", name: "captain", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/PIhSLsf.jpg", name: "captain", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ARiS7WU.jpg", name: "captain", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/3dACz1Z.jpg", name: "captain", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/qEieFwn.jpg", name: "captain", chara: "aeri", category: "normal"},
    // CCAngel
        {image: "https://i.imgur.com/OW8Ss0o.png", name: "ccangel", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/HpWlsCK.png", name: "ccangel", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/18WAf2S.png", name: "ccangel", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/PgbLDfn.png", name: "ccangel", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/UzPnomx.png", name: "ccangel", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/sNdBgkJ.png", name: "ccangel", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/LjRPCqw.png", name: "ccangel", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Jjy8QOl.png", name: "ccangel", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/e7ATcYu.png", name: "ccangel", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/9S4CZEu.png", name: "ccangel", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/bqsyovC.png", name: "ccangel", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/TKZSORX.png", name: "ccangel", chara: "aeri", category: "normal"},
    // char-special
        {image: "https://i.imgur.com/NG31oqM.jpg", name: "noblerose", chara: "violet", category: "char-special"},
        {image: "https://i.imgur.com/9reN4GF.jpg", name: "noblerose", chara: "violet", category: "char-special"},
        {image: "https://i.imgur.com/Nr6ZVj4.jpg", name: "noblerose", chara: "violet", category: "char-special"},
        {image: "https://i.imgur.com/HpKR6Pi.jpg", name: "noblerose", chara: "wolfgang", category: "char-special"},
        {image: "https://i.imgur.com/yvvLTvg.jpg", name: "noblerose", chara: "wolfgang", category: "char-special"},
        {image: "https://i.imgur.com/eWDxdFt.jpg", name: "noblerose", chara: "wolfgang", category: "char-special"},
        {image: "https://i.imgur.com/9lsQ4pV.jpg", name: "rockcat", chara: "luna", category: "char-special"},
        {image: "https://i.imgur.com/lZxvXiY.jpg", name: "rockcat", chara: "luna", category: "char-special"},
        {image: "https://i.imgur.com/DncKtx8.jpg", name: "rockcat", chara: "luna", category: "char-special"},
        {image: "https://i.imgur.com/kSRfW2T.jpg", name: "sweetrider", chara: "soma", category: "char-special"},
        {image: "https://i.imgur.com/xARdgLZ.jpg", name: "sweetrider", chara: "soma", category: "char-special"},
        {image: "https://i.imgur.com/ex6k44R.jpg", name: "sweetrider", chara: "soma", category: "char-special"},
        {image: "https://i.imgur.com/qVuBTnp.jpg", name: "icybutterfly", chara: "bai", category: "char-special"},
        {image: "https://i.imgur.com/3uw5IQv.jpg", name: "icybutterfly", chara: "bai", category: "char-special"},
        {image: "https://i.imgur.com/8Z62T6h.jpg", name: "icybutterfly", chara: "bai", category: "char-special"},
        {image: "https://i.imgur.com/d4i87xS.jpg", name: "blackalice", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/1ZQMOCN.jpg", name: "blackalice", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/MZnQfCi.jpg", name: "blackalice", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/3U14ZMl.jpg", name: "dreamgirl", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/sHFvQ0o.jpg", name: "dreamgirl", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/BYPWVO0.jpg", name: "dreamgirl", chara: "seth", category: "char-special"},
        {image: "https://i.imgur.com/l6sOlrq.jpg", name: "arcanajoker", chara: "mirae", category: "char-special"},
        {image: "https://i.imgur.com/vDWjwlq.jpg", name: "arcanajoker", chara: "mirae", category: "char-special"},
        {image: "https://i.imgur.com/HhJ6yhM.jpg", name: "arcanajoker", chara: "mirae", category: "char-special"},
        {image: "https://i.imgur.com/LNf62ZK.jpg", name: "arcanajoker", chara: "mirae", category: "char-special"},
        {image: "https://i.imgur.com/E6R2qvq.jpg", name: "prometheus", chara: "chulsoo", category: "char-special"},
        {image: "https://i.imgur.com/F9xjAfu.jpg", name: "prometheus", chara: "chulsoo", category: "char-special"},
        {image: "https://i.imgur.com/CJnhEeW.jpg", name: "prometheus", chara: "chulsoo", category: "char-special"},
        {image: "https://i.imgur.com/Oiy5Yg9.jpg", name: "prometheus", chara: "chulsoo", category: "char-special"},
        {image: "https://i.imgur.com/1Zfde6M.jpg", name: "knivesshadow", chara: "eunha", category: "char-special"},
        {image: "https://i.imgur.com/J0ljfAN.jpg", name: "knivesshadow", chara: "eunha", category: "char-special"},
        {image: "https://i.imgur.com/HyazfHn.jpg", name: "knivesshadow", chara: "eunha", category: "char-special"},
        {image: "https://i.imgur.com/xictnCB.jpg", name: "knivesshadow", chara: "eunha", category: "char-special"},
        {image: "https://i.imgur.com/rQN9NGU.jpg", name: "originprayer", chara: "lucy", category: "char-special"},
        {image: "https://i.imgur.com/AG9Ghhb.jpg", name: "originprayer", chara: "lucy", category: "char-special"},
        {image: "https://i.imgur.com/PL54cpz.jpg", name: "originprayer", chara: "lucy", category: "char-special"},
        {image: "https://i.imgur.com/Dxm2C06.jpg", name: "originprayer", chara: "lucy", category: "char-special"},
        {image: "https://i.imgur.com/uAjDtuo.jpg", name: "zodiacnight", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/uJFrkZ8.jpg", name: "zodiacnight", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/fqHfoEw.png", name: "zodiacnight", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/eXf3RNe.png", name: "devilishdream", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/jM0AvjH.png", name: "devilishdream", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/8nubYWz.png", name: "devilishdream", chara: "aeri", category: "char-special"},
        {image: "https://i.imgur.com/x6MNYOQ.png", name: "wannabeyourlove", chara: "ria", category: "char-special"},
        {image: "https://i.imgur.com/VsFd1bx.png", name: "wannabeyourlove", chara: "ria", category: "char-special"},
    // compressor
    // DearCrescent
        {image: "https://i.imgur.com/HUOcLgz.jpg", name: "dearcrescent", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/1ndmRds.jpg", name: "dearcrescent", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/OhjDIp5.jpg", name: "dearcrescent", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/9LiYtbc.jpg", name: "dearcrescent", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/tNAd7tT.jpg", name: "dearcrescent", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/f1n16PL.jpg", name: "dearcrescent", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/4CfcVfF.jpg", name: "dearcrescent", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/N2huGjn.jpg", name: "dearcrescent", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/8wTFjJ9.jpg", name: "dearcrescent", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/YlqvBL2.jpg", name: "dearcrescent", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/c8cANLr.jpg", name: "dearcrescent", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/8NWo7EL.jpg", name: "dearcrescent", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/NJN10hB.jpg", name: "dearcrescent", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/EhfmkNu.jpg", name: "dearcrescent", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QvtI2he.jpg", name: "dearcrescent", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/YYpZbwe.jpg", name: "dearcrescent", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/BFt8Ves.jpg", name: "dearcrescent", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/R2piyJ4.jpg", name: "dearcrescent", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/9vpePlU.jpg", name: "dearcrescent", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/EyKgkp7.png", name: "dearcrescent", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/m7BmHkV.jpg", name: "dearcrescent", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/HN6vzXV.jpg", name: "dearcrescent", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/bYPVA0T.jpg", name: "dearcrescent", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/LkaQel9.jpg", name: "dearcrescent", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/qJreV23.jpg", name: "dearcrescent", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/gpWgVgj.jpg", name: "dearcrescent", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/0qz8e70.jpg", name: "dearcrescent", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/hgsfuhd.jpg", name: "dearcrescent", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/iJJ79aG.jpg", name: "dearcrescent", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/iIh19YF.jpg", name: "dearcrescent", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/812vFfe.jpg", name: "dearcrescent", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Y7EqrRa.jpg", name: "dearcrescent", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/SN5U46W.jpg", name: "dearcrescent", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/1eIJKiz.jpg", name: "dearcrescent", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/u0eda7S.jpg", name: "dearcrescent", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/R1N9n0k.jpg", name: "dearcrescent", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ja8DxtS.jpg", name: "dearcrescent", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/goH2zn5.jpg", name: "dearcrescent", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/YkboCbI.jpg", name: "dearcrescent", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/kZimNRG.png", name: "dearcrescent", chara: "aeri", category: "normal"},
    // domestic
        {image: "https://i.imgur.com/ysdBoom.jpg", name: "domestic", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/MEcKr8I.jpg", name: "domestic", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/QtzmJDD.jpg", name: "domestic", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/OiXwhOx.jpg", name: "domestic", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/1j6FWWl.jpg", name: "domestic", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/l7Mz5Em.jpg", name: "domestic", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/t2MV5IH.jpg", name: "domestic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/jj3UnX8.jpg", name: "domestic", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/LjxIWoh.jpg", name: "domestic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/CIiJuwD.jpg", name: "domestic", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/gbg1hfR.jpg", name: "domestic", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/9Rr6J0b.jpg", name: "domestic", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/s1zw7nZ.jpg", name: "domestic", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/rmzVcf4.jpg", name: "domestic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/kdQAWrM.jpg", name: "domestic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/EnaUMih.jpg", name: "domestic", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/0KQ2NT5.jpg", name: "domestic", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/H4Oma39.jpg", name: "domestic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/1yn491A.jpg", name: "domestic", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/SYQ1SCn.png", name: "domestic", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/QAxxDmn.jpg", name: "domestic", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/q2g51zL.jpg", name: "domestic", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/dq15hWR.jpg", name: "domestic", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/1HPlLoh.jpg", name: "domestic", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/6Gd3oEh.jpg", name: "domestic", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/gwYwpEx.jpg", name: "domestic", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/UsoTuDK.jpg", name: "domestic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/xM5ugSB.jpg", name: "domestic", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/B3wK69G.jpg", name: "domestic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/4nVao5h.jpg", name: "domestic", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/5k00Tn5.jpg", name: "domestic", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/cUoJooW.jpg", name: "domestic", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/hCCjtRO.jpg", name: "domestic", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/coiDPmr.jpg", name: "domestic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/A6l6eiJ.jpg", name: "domestic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/79kToHs.jpg", name: "domestic", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/qLntPpm.jpg", name: "domestic", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/jp6EL24.jpg", name: "domestic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Ht44DMJ.jpg", name: "domestic", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/m8GjZGp.png", name: "domestic", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/kaVRBMI.jpg", name: "domestic", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/LhGaH4P.jpg", name: "domestic", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/hTBLF2S.jpg", name: "domestic", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/9RF3pqI.jpg", name: "domestic", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/05T15T4.jpg", name: "domestic", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/X1sTE9G.jpg", name: "domestic", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Jviq2Wk.jpg", name: "domestic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/MNC7qBK.jpg", name: "domestic", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/tgOWrFX.jpg", name: "domestic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/IDCOtDL.jpg", name: "domestic", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/p5EDHNB.jpg", name: "domestic", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/23BXRUt.jpg", name: "domestic", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/MHOQ8F0.jpg", name: "domestic", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/QLhBYoA.jpg", name: "domestic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/anca9D6.jpg", name: "domestic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/tllK7Vj.jpg", name: "domestic", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/YSB4lJD.jpg", name: "domestic", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/6ybuSbG.jpg", name: "domestic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/HYRPnd9.jpg", name: "domestic", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/IY0VoHc.png", name: "domestic", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/AnzBe95.jpg", name: "domestic", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/BpprJvk.jpg", name: "domestic", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/RW8ydGF.jpg", name: "domestic", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/d9yJrdn.jpg", name: "domestic", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/GoNLngW.jpg", name: "domestic", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/mqxEQ7a.jpg", name: "domestic", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/sJoO6Ri.jpg", name: "domestic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/N3NjQnx.jpg", name: "domestic", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/aBuZ0WM.jpg", name: "domestic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/wWsbpdH.jpg", name: "domestic", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/qE0qLiY.jpg", name: "domestic", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/GaEklvM.jpg", name: "domestic", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/3fSiYVX.jpg", name: "domestic", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/0jPjmiL.jpg", name: "domestic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/iLDWYPo.jpg", name: "domestic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/h8Cl9Bu.jpg", name: "domestic", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/xiVvvjU.jpg", name: "domestic", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/SjvW3WU.jpg", name: "domestic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/356zMFt.jpg", name: "domestic", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/nOLj3vd.png", name: "domestic", chara: "aeri", category: "normal"},
    // Ensemble
        {image: "https://i.imgur.com/jrXI1E1.jpg", name: "ensemble", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/jJmrJsb.jpg", name: "ensemble", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/pRVMJue.jpg", name: "ensemble", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/EYLV5Zy.jpg", name: "ensemble", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/9OAnQQF.jpg", name: "ensemble", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/dEiKgKM.jpg", name: "ensemble", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/XCiry6H.jpg", name: "ensemble", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Z54Jt2V.jpg", name: "ensemble", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/gSC9amx.jpg", name: "ensemble", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UoFTDN9.jpg", name: "ensemble", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/GmgXoBE.jpg", name: "ensemble", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/5beSF8W.jpg", name: "ensemble", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/qm7p5lB.jpg", name: "ensemble", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/7ylRt9v.jpg", name: "ensemble", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/8lvURnH.jpg", name: "ensemble", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/TdCp0Sn.jpg", name: "ensemble", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/RM369zl.jpg", name: "ensemble", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/uVnbElt.jpg", name: "ensemble", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/upQA6dZ.jpg", name: "ensemble", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/QEasobA.jpg", name: "ensemble", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/tPTgwCU.jpg", name: "ensemble", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/xKneAvq.jpg", name: "ensemble", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/EwWNBYm.jpg", name: "ensemble", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/dXJOcSx.jpg", name: "ensemble", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/o2CXKyZ.jpg", name: "ensemble", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/SNMCM7h.jpg", name: "ensemble", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/pzRLviP.jpg", name: "ensemble", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/BZVY5Dk.jpg", name: "ensemble", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/7wrmDGT.jpg", name: "ensemble", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/1sn0Q50.jpg", name: "ensemble", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/gddcRSA.jpg", name: "ensemble", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/rTdM8Dv.jpg", name: "ensemble", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/uNXNAta.jpg", name: "ensemble", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/3kw0tkV.jpg", name: "ensemble", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/dJsUeHi.jpg", name: "ensemble", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/yrTFsur.jpg", name: "ensemble", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/11oUK8B.jpg", name: "ensemble", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Atvqp1Q.jpg", name: "ensemble", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/X1HTBdK.jpg", name: "ensemble", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/LL8Uk6W.jpg", name: "ensemble", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/jA7Jlei.jpg", name: "ensemble", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/LVgOpWI.jpg", name: "ensemble", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/4eqvkqC.jpg", name: "ensemble", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/47bWmJf.jpg", name: "ensemble", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/i5pNA1z.jpg", name: "ensemble", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/VuADO0R.jpg", name: "ensemble", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/fWNaQy6.jpg", name: "ensemble", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/jOp9myL.jpg", name: "ensemble", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/Jwj7uTT.jpg", name: "ensemble", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/XCWuoyH.jpg", name: "ensemble", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/USrGFeh.jpg", name: "ensemble", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/EH55CG2.jpg", name: "ensemble", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/qrGKzLF.jpg", name: "ensemble", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/GvIenbF.jpg", name: "ensemble", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/RWl83e1.jpg", name: "ensemble", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/HpZJffC.jpg", name: "ensemble", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/PHctFPR.jpg", name: "ensemble", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/520WHGf.jpg", name: "ensemble", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/6GfdiGO.jpg", name: "ensemble", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/CmeTP8M.jpg", name: "ensemble", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/BJilQ2L.jpg", name: "ensemble", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ie20VhY.jpg", name: "ensemble", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/8RoZ8c3.jpg", name: "ensemble", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/tyMAK8H.jpg", name: "ensemble", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/FOkarUD.jpg", name: "ensemble", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/kpVge32.jpg", name: "ensemble", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/C8PCWxi.jpg", name: "ensemble", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/MxXEER0.jpg", name: "ensemble", chara: "chulsoo", category: "normal"},
    // evening
        {image: "https://i.imgur.com/b1feOax.jpg", name: "evening", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/gpgBhf0.jpg", name: "evening", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/rUzSbhR.jpg", name: "evening", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/i9vSYGC.jpg", name: "evening", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/NAGyM0O.jpg", name: "evening", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/9abSAug.jpg", name: "evening", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/6gzHkYC.jpg", name: "evening", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/y4sWk1K.jpg", name: "evening", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/FbLMRyz.jpg", name: "evening", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/JDxh9gd.jpg", name: "evening", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/KmVtP4r.jpg", name: "evening", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/61iKRv1.jpg", name: "evening", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/knY4mOS.jpg", name: "evening", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/i3uPUb4.jpg", name: "evening", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QN5i3xD.jpg", name: "evening", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/PnL1FQB.jpg", name: "evening", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/JR93Dhn.jpg", name: "evening", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/gYI2nLq.jpg", name: "evening", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/anbw53q.jpg", name: "evening", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/QEKQmuQ.jpg", name: "evening", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/AZ2BYQc.jpg", name: "evening", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/I6t1tJX.jpg", name: "evening", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Nkhj5by.jpg", name: "evening", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/M06iGeU.jpg", name: "evening", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/M28exzN.jpg", name: "evening", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/YJkQD1b.jpg", name: "evening", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/8aEmxfs.jpg", name: "evening", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/h5VO6DU.jpg", name: "evening", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/fSSP3uJ.jpg", name: "evening", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ydb4UuC.jpg", name: "evening", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Rkn6ibs.jpg", name: "evening", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/qxSXlMA.jpg", name: "evening", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/73Bk1M4.jpg", name: "evening", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/HNfN0MA.jpg", name: "evening", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/HQyjcPN.jpg", name: "evening", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/QApvlDf.jpg", name: "evening", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/VA7mxke.jpg", name: "evening", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/I4fPMRe.jpg", name: "evening", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/YgUxU3b.jpg", name: "evening", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/5uNzuwT.jpg", name: "evening", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/VV45HYP.jpg", name: "evening", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/TswRv2k.jpg", name: "evening", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/l9vJBqY.jpg", name: "evening", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/vre2rKV.jpg", name: "evening", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/aibWJHr.jpg", name: "evening", chara: "seth", category: "normal"},
    // extreme
        {image: "https://i.imgur.com/Fu7RbPQ.jpg", name: "extreme", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/GrHfNYQ.jpg", name: "extreme", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/nbn8ZpG.jpg", name: "extreme", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Ok0ZcEG.jpg", name: "extreme", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/gUhrJGi.jpg", name: "extreme", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/0yL75jJ.jpg", name: "extreme", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/zUb8hdc.jpg", name: "extreme", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/pj1eFA2.jpg", name: "extreme", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/6brYsU9.jpg", name: "extreme", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/9jLIs5k.jpg", name: "extreme", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/S6PndzJ.jpg", name: "extreme", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/9GUutp1.jpg", name: "extreme", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/E7ypCJx.jpg", name: "extreme", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Rk6roCe.jpg", name: "extreme", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/HG2Uq2v.jpg", name: "extreme", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/9YvOSWB.jpg", name: "extreme", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/YouuDof.jpg", name: "extreme", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Ey8XBkQ.jpg", name: "extreme", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/dX23q10.jpg", name: "extreme", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/33b3lGz.jpg", name: "extreme", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/TTIjSRB.jpg", name: "extreme", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/fpx6CAk.jpg", name: "extreme", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/tlLxfBR.jpg", name: "extreme", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/FmHZY0x.jpg", name: "extreme", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/etNkr8m.jpg", name: "extreme", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Iehz4HE.jpg", name: "extreme", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/FnbOKuY.jpg", name: "extreme", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/qkObxvc.jpg", name: "extreme", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/utBaIgm.jpg", name: "extreme", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ZwGr9rj.jpg", name: "extreme", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/b3VqPwi.jpg", name: "extreme", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ulnV7Fq.jpg", name: "extreme", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/rwhySmz.jpg", name: "extreme", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/WD48K7C.jpg", name: "extreme", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/b5EtmqC.jpg", name: "extreme", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/t3zeYaY.jpg", name: "extreme", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/SHubFDw.jpg", name: "extreme", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/gf6enLk.jpg", name: "extreme", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/xGdq9Lw.jpg", name: "extreme", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/eiR0vWK.jpg", name: "extreme", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/NOI27Sq.jpg", name: "extreme", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/vPmgEAP.jpg", name: "extreme", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/FuR3Esn.jpg", name: "extreme", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/x2zYnm4.jpg", name: "extreme", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/2xiULMJ.jpg", name: "extreme", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/G0Iv9cI.jpg", name: "extreme", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/iYGlx4l.jpg", name: "extreme", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/rA30mZE.jpg", name: "extreme", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/RKvKRAs.jpg", name: "extreme", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/zcDBcjA.jpg", name: "extreme", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/Pl2az6m.jpg", name: "extreme", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/iNaSAv8.jpg", name: "extreme", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/5CC6LjY.jpg", name: "extreme", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Z6VVcJk.jpg", name: "extreme", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/lkRIZjI.jpg", name: "extreme", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/5PQyo4F.jpg", name: "extreme", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/5o3ZRQ6.jpg", name: "extreme", chara: "lucy", category: "normal"},
    // Fighting
        {image: "https://i.imgur.com/aiqALwp.jpg", name: "fighting", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/QoEryHr.jpg", name: "fighting", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/SKXSPEZ.jpg", name: "fighting", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Tr5NaBA.jpg", name: "fighting", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/TAaZKoV.jpg", name: "fighting", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/7q0l4Tz.jpg", name: "fighting", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/QHTlik6.jpg", name: "fighting", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/J90BAvA.jpg", name: "fighting", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/rqRa2tC.jpg", name: "fighting", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/S1NurXo.jpg", name: "fighting", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/nZnwjf9.jpg", name: "fighting", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/RCgmTPz.jpg", name: "fighting", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/BSf1t9P.jpg", name: "fighting", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/8NPgMVa.jpg", name: "fighting", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/GTchBN7.jpg", name: "fighting", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/AcCzU4K.jpg", name: "fighting", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/bsaeh2j.jpg", name: "fighting", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/1iAe8WT.jpg", name: "fighting", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/V8uXMUf.jpg", name: "fighting", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/GXdFW91.jpg", name: "fighting", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/8Gx7upy.jpg", name: "fighting", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/QgPDOrv.jpg", name: "fighting", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/CgNm4e2.jpg", name: "fighting", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/aQrLmm1.jpg", name: "fighting", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/OXqtNba.jpg", name: "fighting", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/7Lgsha9.jpg", name: "fighting", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/A1leW4C.jpg", name: "fighting", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/rvOsLXb.jpg", name: "fighting", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/QLu7uGQ.jpg", name: "fighting", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/E7DX5tb.jpg", name: "fighting", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Il0DHdo.jpg", name: "fighting", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Sg0j6b5.jpg", name: "fighting", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/BnZ24uU.jpg", name: "fighting", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Ah46knz.jpg", name: "fighting", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/9KIMlZj.jpg", name: "fighting", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/nNF9jgC.jpg", name: "fighting", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/gegofHf.jpg", name: "fighting", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/oHP4Krk.jpg", name: "fighting", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/uboGPpQ.jpg", name: "fighting", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/G9uDmiQ.jpg", name: "fighting", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/LJG5n3W.jpg", name: "fighting", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/sddFx3g.jpg", name: "fighting", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Zi7W5Rw.jpg", name: "fighting", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/kdpsz6a.jpg", name: "fighting", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/HCBElMJ.jpg", name: "fighting", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/Yc9eSxo.jpg", name: "fighting", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/5lu2vkh.jpg", name: "fighting", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/bPKRN2O.jpg", name: "fighting", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/L1y52Id.jpg", name: "fighting", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/ncxha0a.jpg", name: "fighting", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/yoo0A0s.jpg", name: "fighting", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Go590U2.jpg", name: "fighting", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/GHVAyVi.jpg", name: "fighting", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/tMfGoir.jpg", name: "fighting", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/CyYZPJX.jpg", name: "fighting", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/wYAO6N1.jpg", name: "fighting", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/WqlF1rb.jpg", name: "fighting", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/t3GR8NP.jpg", name: "fighting", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/VmEFBh4.jpg", name: "fighting", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/DjYMdcH.jpg", name: "fighting", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/kwGcCXd.jpg", name: "fighting", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/AHUvRvo.jpg", name: "fighting", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/hSRRp7S.jpg", name: "fighting", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/hglrDFi.jpg", name: "fighting", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/QcVVzix.jpg", name: "fighting", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/WJ9vRXR.jpg", name: "fighting", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/TauSJmL.jpg", name: "fighting", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/EHoFx4J.jpg", name: "fighting", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/hx6YbMv.jpg", name: "fighting", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/ySf3ikO.jpg", name: "fighting", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/dMSr7jS.jpg", name: "fighting", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/o4CFlFc.jpg", name: "fighting", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/Svy0npP.jpg", name: "fighting", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/5cXck0M.jpg", name: "fighting", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ReSUHEd.jpg", name: "fighting", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/APcdvae.jpg", name: "fighting", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/D33lsdJ.jpg", name: "fighting", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/53qR2yK.jpg", name: "fighting", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/XFGjFtA.jpg", name: "fighting", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/JDaVkKD.jpg", name: "fighting", chara: "aeri", category: "normal"},
    // flag
        {image: "https://i.imgur.com/U33gcsd.jpg", name: "flag", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/JnispjN.jpg", name: "flag", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/GOfQI9w.jpg", name: "flag", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Yi1l2Dz.jpg", name: "flag", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/cNjRMaq.jpg", name: "flag", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/HXEFbKX.jpg", name: "flag", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/QljZT6Y.jpg", name: "flag", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ZajhtKr.jpg", name: "flag", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/NmoLx4D.jpg", name: "flag", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/OspHgZi.jpg", name: "flag", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/JciTOOu.jpg", name: "flag", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/va14qwT.jpg", name: "flag", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/rwdREi8.jpg", name: "flag", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/6u7T55I.jpg", name: "flag", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/5dVmIcl.jpg", name: "flag", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/zavzsbw.jpg", name: "flag", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Ig4x149.jpg", name: "flag", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/203lKKv.jpg", name: "flag", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/VJZxekr.jpg", name: "flag", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/4MLHAlU.jpg", name: "flag", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/W7AjRDw.jpg", name: "flag", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/XkPhkta.jpg", name: "flag", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/JouL1Jb.jpg", name: "flag", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/E0eMvvI.jpg", name: "flag", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/fVvGlqy.jpg", name: "flag", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/zNLiCRp.jpg", name: "flag", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Qj8Frhh.jpg", name: "flag", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/pLKgJuF.jpg", name: "flag", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/IZmJpBq.jpg", name: "flag", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/0eZJ5iz.jpg", name: "flag", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/fn7VY39.jpg", name: "flag", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/qgVgCKz.jpg", name: "flag", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/cz16lFo.jpg", name: "flag", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/uWUvjn3.jpg", name: "flag", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/NARj4ZY.jpg", name: "flag", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/hlHzEar.jpg", name: "flag", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/tggbUGV.jpg", name: "flag", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QSyJGwf.jpg", name: "flag", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/tLJj8gE.jpg", name: "flag", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/tl4kio3.jpg", name: "flag", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/p4PIlnl.jpg", name: "flag", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/qNs3S0Z.jpg", name: "flag", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/60xmFB9.jpg", name: "flag", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/IfYOxsR.jpg", name: "flag", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/K6kH07u.jpg", name: "flag", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/4jqKtOA.jpg", name: "flag", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/nArHEIK.jpg", name: "flag", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/0vWwetX.jpg", name: "flag", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/K8Xj1t3.jpg", name: "flag", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/59gNa1f.jpg", name: "flag", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/vPitzgn.jpg", name: "flag", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/5T4Hs9s.jpg", name: "flag", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/XvP1UfI.jpg", name: "flag", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/3blQo59.jpg", name: "flag", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/f9LmQvI.jpg", name: "flag", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/MsFTkzM.jpg", name: "flag", chara: "aeri", category: "normal"},
    // goodnight
        {image: "https://i.imgur.com/JZPDtXQ.jpg", name: "goodnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/ceTvK2c.jpg", name: "goodnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/p0Q9Aio.jpg", name: "goodnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/uWDJIba.jpg", name: "goodnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/iricoFP.png", name: "goodnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/nHrPd7f.png", name: "goodnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/DzEzUej.png", name: "goodnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/10c190Y.jpg", name: "goodnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Z1EqUwr.jpg", name: "goodnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/37B21h8.png", name: "goodnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/wNbWjwE.png", name: "goodnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/p8JAWzT.png", name: "goodnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/ekCBFtR.jpg", name: "goodnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/PAoXAqc.jpg", name: "goodnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/9DQgsen.jpg", name: "goodnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/nu3F0a8.jpg", name: "goodnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/M7rYkxN.png", name: "goodnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/EXHq7LQ.png", name: "goodnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/YCZefk8.png", name: "goodnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/540uaHU.jpg", name: "goodnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/cyobnWZ.jpg", name: "goodnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/NAsF5ua.png", name: "goodnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/bZdYkiB.png", name: "goodnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/6elaUg2.png", name: "goodnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/RjPp8SZ.jpg", name: "goodnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/JXlWUGQ.jpg", name: "goodnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/oC7RJdT.jpg", name: "goodnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/h85mK7R.jpg", name: "goodnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/YE3GWXJ.png", name: "goodnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/SFIH3Lg.png", name: "goodnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/GI5rewy.png", name: "goodnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/1mot6xD.jpg", name: "goodnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/uCgC9SL.jpg", name: "goodnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/G6m0761.png", name: "goodnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/4Xz7p86.png", name: "goodnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/QhQS3qh.png", name: "goodnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/bV28TEB.jpg", name: "goodnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/YwD6L7p.jpg", name: "goodnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/k19lcKW.jpg", name: "goodnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/z1ZOBTS.jpg", name: "goodnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/JYHmi3d.png", name: "goodnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/DebAarh.png", name: "goodnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/2Deu5Ir.png", name: "goodnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ab6GTq3.jpg", name: "goodnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/QUcvf8E.jpg", name: "goodnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/fFK0rdA.png", name: "goodnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/z2XsGcB.png", name: "goodnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/VBdZZxd.png", name: "goodnight", chara: "aeri", category: "normal"},
    // ground_division
        {image: "https://i.imgur.com/tPRR8Zy.jpg", name: "ground_division", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/8e0huIq.jpg", name: "ground_division", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/lwZ9FDv.jpg", name: "ground_division", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/zxyUMm8.jpg", name: "ground_division", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Tiixxq4.jpg", name: "ground_division", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/uI4yuni.jpg", name: "ground_division", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Cylby0a.jpg", name: "ground_division", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/3IPmlSS.jpg", name: "ground_division", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/vLob9vZ.jpg", name: "ground_division", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/aoaUuwM.jpg", name: "ground_division", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/dQjpCDa.jpg", name: "ground_division", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/IilZAqO.jpg", name: "ground_division", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/oNKVZVa.jpg", name: "ground_division", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/SifOX9g.jpg", name: "ground_division", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/kO0xRcL.jpg", name: "ground_division", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UgglVBP.jpg", name: "ground_division", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/GiCJZCD.jpg", name: "ground_division", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/tN7FJoK.jpg", name: "ground_division", chara: "seth", category: "normal"},
    // holynight
        {image: "https://i.imgur.com/dNrFv0z.jpg", name: "holynight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/pOUn6R3.jpg", name: "holynight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/nMiQJqy.jpg", name: "holynight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/lUVJs2I.jpg", name: "holynight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/SDHZY9s.jpg", name: "holynight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/n1J2omA.jpg", name: "holynight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/ZPoFsMy.jpg", name: "holynight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/asjEMGc.jpg", name: "holynight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/zYOksLh.jpg", name: "holynight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/g5EP5rB.jpg", name: "holynight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/fsDVq4W.jpg", name: "holynight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/2XsO8Cc.jpg", name: "holynight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/it4mUqd.jpg", name: "holynight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/aDsQQsG.jpg", name: "holynight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/iz6Lva6.jpg", name: "holynight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/euRpu3M.jpg", name: "holynight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/nYgs0BQ.jpg", name: "holynight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/m4Tr86m.jpg", name: "holynight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/JXLeOkY.jpg", name: "holynight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/weKv8kK.png", name: "holynight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/zIYTy8g.jpg", name: "holynight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/rFWvYlv.jpg", name: "holynight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/6YKir2d.jpg", name: "holynight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/YrmagCA.jpg", name: "holynight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/LqYDMRc.jpg", name: "holynight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/vOoAaSk.jpg", name: "holynight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/nawHCHe.jpg", name: "holynight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/ZEytW0c.jpg", name: "holynight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/SfJh8RW.jpg", name: "holynight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/YSI9BCt.jpg", name: "holynight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ldc8ZU2.jpg", name: "holynight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/bYa0K59.jpg", name: "holynight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/YS2qU0g.jpg", name: "holynight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Vi55Lqp.jpg", name: "holynight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/MTlCJMY.jpg", name: "holynight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/TUjVKPc.jpg", name: "holynight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/GeY3pU9.jpg", name: "holynight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/LqxsdeE.jpg", name: "holynight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/xwi5dky.jpg", name: "holynight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/xZNjCwQ.png", name: "holynight", chara: "aeri", category: "normal"},
    // imperial
        {image: "https://i.imgur.com/JbHDFrJ.jpg", name: "imperial", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/aFHyspN.jpg", name: "imperial", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/3rTyjT7.jpg", name: "imperial", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/a9dwwRj.jpg", name: "imperial", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/cJHweNG.jpg", name: "imperial", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/UTSXAye.jpg", name: "imperial", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/WepmzG8.jpg", name: "imperial", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/7UChFag.jpg", name: "imperial", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/3G52Y3n.jpg", name: "imperial", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/RBX0Ezn.jpg", name: "imperial", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/70JAqzL.jpg", name: "imperial", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/OPZnB2z.jpg", name: "imperial", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/yqlWkl3.jpg", name: "imperial", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/gkCuG48.jpg", name: "imperial", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/thPpal7.jpg", name: "imperial", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/7u5gfjX.jpg", name: "imperial", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/IfntbpW.jpg", name: "imperial", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/rxfwdUy.jpg", name: "imperial", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/YWfvESV.jpg", name: "imperial", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/B9qpKSD.jpg", name: "imperial", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/t6u2q26.jpg", name: "imperial", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/YqEIQhl.jpg", name: "imperial", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ffkuEId.jpg", name: "imperial", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/TRGqbPg.jpg", name: "imperial", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/eTSJenf.jpg", name: "imperial", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/iMxcoa2.jpg", name: "imperial", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/IkFxAi4.jpg", name: "imperial", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/jNz2W62.jpg", name: "imperial", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/bO1Nl3e.jpg", name: "imperial", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Umz010I.jpg", name: "imperial", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/9AmFXsX.jpg", name: "imperial", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/wuLvSvT.jpg", name: "imperial", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/T0VrBLF.jpg", name: "imperial", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/OamKtEA.jpg", name: "imperial", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/x5LuASj.jpg", name: "imperial", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/0Vg5qbd.jpg", name: "imperial", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/7uEf5jb.jpg", name: "imperial", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/B6eEGkF.jpg", name: "imperial", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/TocpZZM.jpg", name: "imperial", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/G9dzrui.jpg", name: "imperial", chara: "lucy", category: "normal"},
    // LittleDemon
        {image: "https://i.imgur.com/skZ7ACN.jpg", name: "littledemon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/HIbzMBT.jpg", name: "littledemon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/jFumflR.jpg", name: "littledemon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/wb13ofa.jpg", name: "littledemon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/aiv68im.jpg", name: "littledemon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/qYGi1QL.jpg", name: "littledemon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/nY6Xuj7.jpg", name: "littledemon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/bQPrJLd.jpg", name: "littledemon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/1HJM5Ih.jpg", name: "littledemon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/KBz735F.jpg", name: "littledemon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/fuizERw.jpg", name: "littledemon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/rurzehI.jpg", name: "littledemon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/QK09ces.jpg", name: "littledemon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/JkRZ4mw.jpg", name: "littledemon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/nCMvVId.jpg", name: "littledemon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/q5TfWTG.jpg", name: "littledemon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/zq0bq1D.jpg", name: "littledemon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Sq0aeor.jpg", name: "littledemon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/biuxRap.jpg", name: "littledemon", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/3Py2lI1.jpg", name: "littledemon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/zZexQxb.jpg", name: "littledemon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Zlqkijo.jpg", name: "littledemon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/XDsFr1Z.jpg", name: "littledemon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/VEYc5fY.jpg", name: "littledemon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/cs9uD8m.jpg", name: "littledemon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/tz5JrZz.jpg", name: "littledemon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/fEO8Dke.jpg", name: "littledemon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/T5vb1iS.jpg", name: "littledemon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/hZHtY70.jpg", name: "littledemon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/BccZu2X.jpg", name: "littledemon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/WdALKwU.jpg", name: "littledemon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/0Ee8mVw.jpg", name: "littledemon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/C8ET9Vx.jpg", name: "littledemon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/7YxGlry.jpg", name: "littledemon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/eo7mOoV.jpg", name: "littledemon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/Pyenill.jpg", name: "littledemon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/GVNaWjz.jpg", name: "littledemon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/byv3r2L.jpg", name: "littledemon", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/7nh8IqU.jpg", name: "littledemon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/GVmZ0IQ.jpg", name: "littledemon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/T8o0HjC.jpg", name: "littledemon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/GLt4LG6.jpg", name: "littledemon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Ocy9hBm.jpg", name: "littledemon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/TtEvzMX.jpg", name: "littledemon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/oX5IJ3j.jpg", name: "littledemon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/ZUlwKfL.jpg", name: "littledemon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/1cIFqOI.jpg", name: "littledemon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/3LfiZlz.jpg", name: "littledemon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/cK0CNgn.jpg", name: "littledemon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/rcY06AE.jpg", name: "littledemon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/X8RTgBM.jpg", name: "littledemon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/cduayzV.jpg", name: "littledemon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/HJmsHoR.jpg", name: "littledemon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/PpTBYdk.jpg", name: "littledemon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/zoobMzK.jpg", name: "littledemon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/zdAMitd.jpg", name: "littledemon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/D0WZ64W.jpg", name: "littledemon", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/ibVRyeV.jpg", name: "littledemon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/ivm3lqn.jpg", name: "littledemon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/FEEebOs.jpg", name: "littledemon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/sPvkQ82.jpg", name: "littledemon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/ZD3nRYQ.jpg", name: "littledemon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/NjEbH6Q.jpg", name: "littledemon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/HeEPFnM.jpg", name: "littledemon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/tnaRaVR.jpg", name: "littledemon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/b14KzYf.jpg", name: "littledemon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/HZpQihi.jpg", name: "littledemon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/tihumcV.jpg", name: "littledemon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/axj7jVp.jpg", name: "littledemon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/3k6QR3b.jpg", name: "littledemon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/AXadAmr.jpg", name: "littledemon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/nYbwEiq.jpg", name: "littledemon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/A53672M.jpg", name: "littledemon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/crI1O0e.jpg", name: "littledemon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/QY9t5vD.jpg", name: "littledemon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/VHgKAtt.jpg", name: "littledemon", chara: "lucy", category: "normal"},
    // lolipop
        {image: "https://i.imgur.com/DPwxlBe.jpg", name: "lolipop", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/MBXkl2k.jpg", name: "lolipop", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/q2DHY1l.jpg", name: "lolipop", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/BXhjvHt.jpg", name: "lolipop", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/eqV71ar.jpg", name: "lolipop", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/M65g4ka.jpg", name: "lolipop", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Ov3JpgB.jpg", name: "lolipop", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/W4UZN8Y.jpg", name: "lolipop", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ZE4xNrx.jpg", name: "lolipop", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/z7fAkXQ.jpg", name: "lolipop", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/mNvBp1Q.jpg", name: "lolipop", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/hwATJUv.jpg", name: "lolipop", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/hY2MBQC.jpg", name: "lolipop", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/z2d1CQZ.jpg", name: "lolipop", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/1yf2ZAh.jpg", name: "lolipop", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/4kFewsA.jpg", name: "lolipop", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/jGmtTML.jpg", name: "lolipop", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/9W2teT8.jpg", name: "lolipop", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/z08nij3.jpg", name: "lolipop", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/4eBj6sF.jpg", name: "lolipop", chara: "lucy", category: "normal"},

    // midnight
        {image: "https://i.imgur.com/Ivd3LzA.jpg", name: "midnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/0JvXIBp.jpg", name: "midnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/98hZXjo.jpg", name: "midnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/cBI00Mg.jpg", name: "midnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Fd0tnL1.jpg", name: "midnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/X9nbfAy.jpg", name: "midnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/EiDeGDQ.jpg", name: "midnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/1goTRUR.jpg", name: "midnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/6xc0lBp.jpg", name: "midnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/SJFlubk.jpg", name: "midnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/JWmix7Z.jpg", name: "midnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/zr0RajJ.jpg", name: "midnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/EHrX3bC.jpg", name: "midnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/rplM3I3.jpg", name: "midnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/8nqL2ID.jpg", name: "midnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/pTeYXO3.jpg", name: "midnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/3EtM8Ii.jpg", name: "midnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/8LfFwf9.jpg", name: "midnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/7ACFCmw.jpg", name: "midnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/YvFN0GK.png", name: "midnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/gXnENXi.jpg", name: "midnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Xvpu6yh.jpg", name: "midnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/m3orssN.jpg", name: "midnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/tw2vXP2.jpg", name: "midnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/XsZDIRs.jpg", name: "midnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/d7tIXNj.jpg", name: "midnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/kF3Ilrc.jpg", name: "midnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/xS1QjiX.jpg", name: "midnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/SbmeiXJ.jpg", name: "midnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/DgMbmtf.jpg", name: "midnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/lBlkvXB.jpg", name: "midnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/RGHsHcD.jpg", name: "midnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/qbnbAj7.jpg", name: "midnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/lgm6U30.jpg", name: "midnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/8EHenEI.jpg", name: "midnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/TqL8WJK.jpg", name: "midnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/QgkTEqW.jpg", name: "midnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/3ryrBOy.jpg", name: "midnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/CcEoWZZ.jpg", name: "midnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/EDwlwYI.png", name: "midnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/mIAj4d5.jpg", name: "midnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/xbcrTH5.jpg", name: "midnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/0bW7GPv.jpg", name: "midnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/QesLUSv.jpg", name: "midnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/K9rP6RW.jpg", name: "midnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/DL26ZvQ.jpg", name: "midnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/VQfyPBr.jpg", name: "midnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/aASQuqo.jpg", name: "midnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/fvDzXqx.jpg", name: "midnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/hAnxiFk.jpg", name: "midnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/zGctlJW.jpg", name: "midnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Q64Kbq6.jpg", name: "midnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/KujVGvv.jpg", name: "midnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/XERUiby.jpg", name: "midnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/zvcSoDW.jpg", name: "midnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/fTD6LEY.jpg", name: "midnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/hC5TGWI.jpg", name: "midnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/UGd771F.jpg", name: "midnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/VD6WYlU.jpg", name: "midnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/478M2NR.png", name: "midnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/TNRDMai.jpg", name: "midnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/N2Q7sA6.jpg", name: "midnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/SfZEhe8.jpg", name: "midnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/LaJFtZI.jpg", name: "midnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/EbPqajI.jpg", name: "midnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/1vGS2B8.jpg", name: "midnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/25Ue53p.jpg", name: "midnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/52L2v7U.jpg", name: "midnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/HNtcCjc.jpg", name: "midnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/SKtbPti.jpg", name: "midnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/WRGaxf5.jpg", name: "midnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/iErM3Xa.jpg", name: "midnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/MICDkdi.jpg", name: "midnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/A58vfV6.jpg", name: "midnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/wi1G60v.jpg", name: "midnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/e9nCyix.jpg", name: "midnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/2GVvlc6.jpg", name: "midnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/QJYb2Zz.jpg", name: "midnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/NSGTWnT.jpg", name: "midnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/cVLUcdi.png", name: "midnight", chara: "aeri", category: "normal"},
    // MiracleSchool
        {image: "https://i.imgur.com/RacmV3l.jpg", name: "miracleschool", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/XR64Vjj.jpg", name: "miracleschool", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/7Ni1iPz.jpg", name: "miracleschool", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/UTTlill.jpg", name: "miracleschool", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/tIXEhSk.jpg", name: "miracleschool", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/JECKTte.jpg", name: "miracleschool", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/T4gVvX4.jpg", name: "miracleschool", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Z1njl5T.jpg", name: "miracleschool", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/8zWMQaX.jpg", name: "miracleschool", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/108bMiH.jpg", name: "miracleschool", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ASMxWNh.jpg", name: "miracleschool", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/btx9YBA.jpg", name: "miracleschool", chara: "eunha", category: "normal"},
    // morning
        {image: "https://i.imgur.com/zyOPqrH.jpg", name: "morning", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/U9uL5Nx.jpg", name: "morning", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/X2LP61Q.jpg", name: "morning", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/w7CsV7n.jpg", name: "morning", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/KvXxETw.jpg", name: "morning", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/0JjtXrk.jpg", name: "morning", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/GRQtjpT.jpg", name: "morning", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/4j4wG0W.jpg", name: "morning", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/aii0nfo.jpg", name: "morning", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Ko5xiOw.jpg", name: "morning", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/OT1nVTv.jpg", name: "morning", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/4pkCTDK.jpg", name: "morning", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/eBSbQcp.jpg", name: "morning", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Mnqi0Re.jpg", name: "morning", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/NGiumQY.jpg", name: "morning", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/dl5eHef.jpg", name: "morning", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/AqxLBsQ.jpg", name: "morning", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/aAMLfcz.jpg", name: "morning", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/JgBucPt.jpg", name: "morning", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/OQJIl90.jpg", name: "morning", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/tqy3Ov6.jpg", name: "morning", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/mT57Rtc.jpg", name: "morning", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/kOZMHAZ.jpg", name: "morning", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/hAAFW89.jpg", name: "morning", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/Plzx68C.jpg", name: "morning", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Lo53rwY.jpg", name: "morning", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/GgaN2Sn.jpg", name: "morning", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/rSm8uEY.jpg", name: "morning", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/8488R2h.jpg", name: "morning", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/f7RmbCz.jpg", name: "morning", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/hDbCgO6.jpg", name: "morning", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/z3ZnDPg.jpg", name: "morning", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/dKQnTFW.jpg", name: "morning", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/UmeIllR.jpg", name: "morning", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/N529GQV.jpg", name: "morning", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/VcZBWFq.jpg", name: "morning", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/OWOAANq.jpg", name: "morning", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/YFk5UeL.jpg", name: "morning", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/JVBAlmc.jpg", name: "morning", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/LPfLSk6.jpg", name: "morning", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/FyfXhNH.jpg", name: "morning", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/iSsLu3X.jpg", name: "morning", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/ERE2Exy.jpg", name: "morning", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/42CzLms.jpg", name: "morning", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/uqJG2un.jpg", name: "morning", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/WFZ0wPS.jpg", name: "morning", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/LtBFK08.jpg", name: "morning", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/8z60hra.jpg", name: "morning", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/DEVbOTx.jpg", name: "morning", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/jbxHB1c.jpg", name: "morning", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/9TCUCKj.jpg", name: "morning", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/jMSMmn5.jpg", name: "morning", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/5ak16Rd.jpg", name: "morning", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/AywZ5Dx.jpg", name: "morning", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ybuCczq.jpg", name: "morning", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/3Bcbi6f.jpg", name: "morning", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/QA8T2XJ.jpg", name: "morning", chara: "lucy", category: "normal"},
    // mystic-shuffle
        {image: "https://i.imgur.com/GOgAtv9.jpg", name: "mystic-shuffle", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/jLIsgey.jpg", name: "mystic-shuffle", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/IxhrJnX.jpg", name: "mystic-shuffle", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/rSF3NSA.jpg", name: "mystic-shuffle", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/KpItEvf.jpg", name: "mystic-shuffle", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/NK4GIm1.jpg", name: "mystic-shuffle", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/jGKO29D.jpg", name: "mystic-shuffle", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/TVRnbLi.jpg", name: "mystic-shuffle", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/IedvkAA.jpg", name: "mystic-shuffle", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Uaf9jL8.jpg", name: "mystic-shuffle", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/DXSgKJi.jpg", name: "mystic-shuffle", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/PPZqGDl.jpg", name: "mystic-shuffle", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/jgc4cW1.jpg", name: "mystic-shuffle", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/12rVKjJ.jpg", name: "mystic-shuffle", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/BdOF5E4.jpg", name: "mystic-shuffle", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/iqCCbyf.jpg", name: "mystic-shuffle", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/5Bm13AT.jpg", name: "mystic-shuffle", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/u5zKy6s.jpg", name: "mystic-shuffle", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ovQr7dy.jpg", name: "mystic-shuffle", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/yQyk5TR.jpg", name: "mystic-shuffle", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/BY816sQ.jpg", name: "mystic-shuffle", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/xBr4s9s.jpg", name: "mystic-shuffle", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/kq0FHg0.jpg", name: "mystic-shuffle", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/KI5IGgm.jpg", name: "mystic-shuffle", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/qLbURbP.jpg", name: "mystic-shuffle", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/wfTNlSl.jpg", name: "mystic-shuffle", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Ne1rFco.jpg", name: "mystic-shuffle", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/wZPfHRi.jpg", name: "mystic-shuffle", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/vLV38Xl.jpg", name: "mystic-shuffle", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/xOGQHYX.jpg", name: "mystic-shuffle", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/a6Qa7el.jpg", name: "mystic-shuffle", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/CfKMJTL.jpg", name: "mystic-shuffle", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ZAJNP7n.jpg", name: "mystic-shuffle", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/L4xITGS.jpg", name: "mystic-shuffle", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/YOan19G.jpg", name: "mystic-shuffle", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/5GGmgwQ.jpg", name: "mystic-shuffle", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/jBanZRY.jpg", name: "mystic-shuffle", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/DXK3lrN.jpg", name: "mystic-shuffle", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/F39M7iN.jpg", name: "mystic-shuffle", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/vvczLu0.jpg", name: "mystic-shuffle", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/vYuLbuH.jpg", name: "mystic-shuffle", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/81BmT3V.jpg", name: "mystic-shuffle", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/CLpjRfQ.jpg", name: "mystic-shuffle", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/WPXP0v5.jpg", name: "mystic-shuffle", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/05v04mR.jpg", name: "mystic-shuffle", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/CDP0PAe.jpg", name: "mystic-shuffle", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/265fWMl.jpg", name: "mystic-shuffle", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/2yuR7xx.jpg", name: "mystic-shuffle", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/FbecgRc.jpg", name: "mystic-shuffle", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/zHUma9m.jpg", name: "mystic-shuffle", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/BqVrdUJ.jpg", name: "mystic-shuffle", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Hvpud4w.jpg", name: "mystic-shuffle", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/w47kFbZ.jpg", name: "mystic-shuffle", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/36XZKMU.jpg", name: "mystic-shuffle", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/kOmjO9K.jpg", name: "mystic-shuffle", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/8AxxO1n.jpg", name: "mystic-shuffle", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/O1GhP8q.jpg", name: "mystic-shuffle", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/FMsXqvx.jpg", name: "mystic-shuffle", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/g7Hu2HI.jpg", name: "mystic-shuffle", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/srkqix5.jpg", name: "mystic-shuffle", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/EQdnpjg.jpg", name: "mystic-shuffle", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/GRsPpyE.jpg", name: "mystic-shuffle", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/DargvQ1.jpg", name: "mystic-shuffle", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Ujs4Iuq.jpg", name: "mystic-shuffle", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/bBLMMSl.jpg", name: "mystic-shuffle", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/HMaWkl2.jpg", name: "mystic-shuffle", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/u43fSMe.jpg", name: "mystic-shuffle", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/knrCSS6.jpg", name: "mystic-shuffle", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/OX9yKDm.jpg", name: "mystic-shuffle", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/GBAtaIs.jpg", name: "mystic-shuffle", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/wAefamy.jpg", name: "mystic-shuffle", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/PyY0OaP.jpg", name: "mystic-shuffle", chara: "eunha", category: "normal"},
    // nano
        {image: "https://i.imgur.com/I5yBhsd.jpg", name: "nano", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/IugKXGD.jpg", name: "nano", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/ePPaATq.jpg", name: "nano", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/TEhySRX.jpg", name: "nano", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/mtgGHpm.jpg", name: "nano", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/PGtrXAl.jpg", name: "nano", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/JuWYKYC.jpg", name: "nano", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/u36DMWL.jpg", name: "nano", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/11Bo9l4.jpg", name: "nano", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/o9xBOKP.jpg", name: "nano", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/OHmwFsA.jpg", name: "nano", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/nzqaS94.jpg", name: "nano", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/0wcQY2k.jpg", name: "nano", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/P2xK5Vy.jpg", name: "nano", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/OaWmk2Y.jpg", name: "nano", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/4cCQIHw.jpg", name: "nano", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/khCIaE8.jpg", name: "nano", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/o8QTnZq.jpg", name: "nano", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/gKbenUn.jpg", name: "nano", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/dxfxxK4.jpg", name: "nano", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/GFh6W4k.jpg", name: "nano", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/72MOfm1.jpg", name: "nano", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/jzK6unx.jpg", name: "nano", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/0RJeztd.jpg", name: "nano", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/sbtOXPk.jpg", name: "nano", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/c8DLoYt.jpg", name: "nano", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/YPwHMvA.jpg", name: "nano", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/C9pswGA.jpg", name: "nano", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/1alT5pU.jpg", name: "nano", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/xqPq3EO.jpg", name: "nano", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/tWd2nRj.jpg", name: "nano", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/2mZ2kbi.jpg", name: "nano", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/zOwg1zl.jpg", name: "nano", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/e2iig8v.jpg", name: "nano", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/GXhhSVg.jpg", name: "nano", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/HU9FYGx.jpg", name: "nano", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/CnYiB8W.jpg", name: "nano", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/23Aidfk.jpg", name: "nano", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/6gzCKwW.jpg", name: "nano", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/oTkdXDp.jpg", name: "nano", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/aoRGlyw.jpg", name: "nano", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/N4ugF9e.jpg", name: "nano", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/fps9mNF.jpg", name: "nano", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Vgo89Y7.jpg", name: "nano", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ZH2Gjb4.jpg", name: "nano", chara: "seth", category: "normal"},
    // nightmare
        {image: "https://i.imgur.com/q9Sa5Fh.jpg", name: "nightmare", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/aY8d7LE.jpg", name: "nightmare", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/j6uU63c.jpg", name: "nightmare", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/WQGArg6.jpg", name: "nightmare", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/PehCkP2.jpg", name: "nightmare", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/AkyUUrK.jpg", name: "nightmare", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/gKA1e7P.jpg", name: "nightmare", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/ZdUKWkP.jpg", name: "nightmare", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/rG3CZUA.jpg", name: "nightmare", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UqyxqJn.jpg", name: "nightmare", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/tKODVEw.jpg", name: "nightmare", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/NPuVaQu.jpg", name: "nightmare", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/8GThJL5.jpg", name: "nightmare", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/km63ttE.jpg", name: "nightmare", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ftzPBhR.png", name: "nightmare", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/4ywsnXz.jpg", name: "nightmare", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/zV5EMnY.jpg", name: "nightmare", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/oZeoq3Y.jpg", name: "nightmare", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/gl76VgR.jpg", name: "nightmare", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/Pcums07.png", name: "nightmare", chara: "aeri", category: "normal"},
    // officelife
        {image: "https://i.imgur.com/6e6KHEG.jpg", name: "officelife", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/C09S9Xd.jpg", name: "officelife", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/NpauWvl.jpg", name: "officelife", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/JQt2DeH.jpg", name: "officelife", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/sDM6Vyf.jpg", name: "officelife", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/U3DFWZm.jpg", name: "officelife", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/UvsGo60.jpg", name: "officelife", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/vqvoXSn.jpg", name: "officelife", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/ARL9e5A.jpg", name: "officelife", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/iaI7mOL.jpg", name: "officelife", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/VjsdLXb.jpg", name: "officelife", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/pVofgbF.jpg", name: "officelife", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/60jVPW3.jpg", name: "officelife", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Ug1Gffy.jpg", name: "officelife", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Y8Em9YR.jpg", name: "officelife", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/gHjOCeM.jpg", name: "officelife", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/vPIFlI4.jpg", name: "officelife", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/psKKSwq.jpg", name: "officelife", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/D8UfbFB.jpg", name: "officelife", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/XXUNsx5.jpg", name: "officelife", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/uwsX3Vy.jpg", name: "officelife", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/XlrHlLU.jpg", name: "officelife", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/gLXVt5A.jpg", name: "officelife", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/T2gMIwa.jpg", name: "officelife", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/2oI34ZG.jpg", name: "officelife", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/r1D1Bfj.jpg", name: "officelife", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/M0qiejc.jpg", name: "officelife", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/YN01yAK.jpg", name: "officelife", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/KXtLLle.jpg", name: "officelife", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/pgiIobB.jpg", name: "officelife", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/sMl6bd3.jpg", name: "officelife", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/PMkillO.jpg", name: "officelife", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Mucq2zV.jpg", name: "officelife", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/dfK55te.jpg", name: "officelife", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/MXcNqtF.jpg", name: "officelife", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/BDAZ5YT.jpg", name: "officelife", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/9v6Vc4g.jpg", name: "officelife", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/tDdJDaQ.jpg", name: "officelife", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/S97QSxn.jpg", name: "officelife", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/c0s6X2j.jpg", name: "officelife", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/91DJg7V.jpg", name: "officelife", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/Uje8oHG.jpg", name: "officelife", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/dcgLIwV.jpg", name: "officelife", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/b0fRqHd.jpg", name: "officelife", chara: "eunha", category: "normal"},
    // Picnicking
        {image: "https://i.imgur.com/707WASm.jpg", name: "picnicking", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/pidYWlP.jpg", name: "picnicking", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/AytpDr6.jpg", name: "picnicking", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/k9va1Gp.jpg", name: "picnicking", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Hvy1P69.jpg", name: "picnicking", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/PbLeJn8.jpg", name: "picnicking", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/okSRWFO.jpg", name: "picnicking", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/MIkQUCd.jpg", name: "picnicking", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/yRA3Ieg.jpg", name: "picnicking", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/HDSyi5k.jpg", name: "picnicking", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/gQ3xm3F.jpg", name: "picnicking", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/gegyKCK.jpg", name: "picnicking", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/ySDkLs5.jpg", name: "picnicking", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/PboU5uf.jpg", name: "picnicking", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/tbuBib8.jpg", name: "picnicking", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/w7FAYcT.jpg", name: "picnicking", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/T2WVnAy.jpg", name: "picnicking", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/5OSPUgh.jpg", name: "picnicking", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/taV4meN.jpg", name: "picnicking", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/JPfomBc.jpg", name: "picnicking", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/fQ77Mrp.jpg", name: "picnicking", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/boiYZL7.jpg", name: "picnicking", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/lcPkTa0.jpg", name: "picnicking", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/6uzvKXo.jpg", name: "picnicking", chara: "chulsoo", category: "normal"},
    // Princess
        {image: "https://i.imgur.com/bsCIY0A.jpg", name: "princess", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/SN7pObm.jpg", name: "princess", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/SROSQLI.jpg", name: "princess", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/O8Zk8gv.jpg", name: "princess", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/7uzRlfW.jpg", name: "princess", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/XvjM8VT.jpg", name: "princess", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/JHEK1BN.jpg", name: "princess", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/UGakgq1.jpg", name: "princess", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/sy1QrPi.jpg", name: "princess", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/iySc8ns.jpg", name: "princess", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/TKHDJeJ.jpg", name: "princess", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Tg3yR4P.jpg", name: "princess", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/iicyY4M.jpg", name: "princess", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/poVxkTp.jpg", name: "princess", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/bjV5BJZ.jpg", name: "princess", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/PcKwCTB.jpg", name: "princess", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/qcCSYBS.jpg", name: "princess", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/DdB0hGQ.jpg", name: "princess", chara: "lucy", category: "normal"},
    // rabbit
        {image: "https://i.imgur.com/4kghAbR.png", name: "rabbit", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/tRhQ1Hs.png", name: "rabbit", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/y7KLUbc.png", name: "rabbit", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/6TC4nrF.png", name: "rabbit", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/zAbgWz9.png", name: "rabbit", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/e96hQJC.png", name: "rabbit", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/iT2SZux.png", name: "rabbit", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/3Ct5kP0.png", name: "rabbit", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/EUzQHye.png", name: "rabbit", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/7jkOvpF.png", name: "rabbit", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/kDVpSox.png", name: "rabbit", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/Mg7upWN.png", name: "rabbit", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/0DRRkGI.png", name: "rabbit", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/xgsCDVo.png", name: "rabbit", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/cXTf2Kq.png", name: "rabbit", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/ajmJKAB.png", name: "rabbit", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/AxjgRwQ.png", name: "rabbit", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/v7QJAER.png", name: "rabbit", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/yd3Jog1.png", name: "rabbit", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/7mJTS9N.png", name: "rabbit", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/zstQx0A.png", name: "rabbit", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/Zd68q7c.png", name: "rabbit", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/FsxdXDM.png", name: "rabbit", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/p8pAjII.png", name: "rabbit", chara: "aeri", category: "normal"},
    // ranger
        {image: "https://i.imgur.com/CAAigLO.jpg", name: "ranger", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/soL4cE2.jpg", name: "ranger", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Kh3hBXy.jpg", name: "ranger", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/QYs8sdE.jpg", name: "ranger", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/ai0B9zt.jpg", name: "ranger", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/UQQcphB.jpg", name: "ranger", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/dmfCEmF.jpg", name: "ranger", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/nVvBT7S.jpg", name: "ranger", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/NsnxPZo.jpg", name: "ranger", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/aWfr4hv.jpg", name: "ranger", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/RhH91dw.jpg", name: "ranger", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/LgMq0qU.jpg", name: "ranger", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/aZXorrz.jpg", name: "ranger", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ffzwBDY.jpg", name: "ranger", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/tdds3z5.jpg", name: "ranger", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/tlVGEBg.jpg", name: "ranger", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/o7BuB65.jpg", name: "ranger", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/lqyBBmT.jpg", name: "ranger", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/M5SNW7H.jpg", name: "ranger", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/mD4YabT.jpg", name: "ranger", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/qgCLs2G.jpg", name: "ranger", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/8UMI8aB.jpg", name: "ranger", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/gazD3Pz.jpg", name: "ranger", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/o3JHDeS.jpg", name: "ranger", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/1dH5qEo.jpg", name: "ranger", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/G2GE0B8.jpg", name: "ranger", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/b9amRAQ.jpg", name: "ranger", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/hSItRnW.jpg", name: "ranger", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/H35SA4R.jpg", name: "ranger", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/vDroiXx.jpg", name: "ranger", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/ztpKeMu.jpg", name: "ranger", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/m8wNdXV.jpg", name: "ranger", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/bW3fXG5.jpg", name: "ranger", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/7kdZMJ9.jpg", name: "ranger", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/WqCJWJY.jpg", name: "ranger", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/KvbLtd5.jpg", name: "ranger", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/PaSRdHT.jpg", name: "ranger", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/frc7YzC.jpg", name: "ranger", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/JGwDsXj.jpg", name: "ranger", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/W9KvmM8.jpg", name: "ranger", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/nTMyzuZ.jpg", name: "ranger", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/I1vj2iM.jpg", name: "ranger", chara: "bai", category: "normal"},
    // romance
        {image: "https://i.imgur.com/w9HcfKS.jpg", name: "romance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/ES6CoUg.jpg", name: "romance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/rOVFvv9.jpg", name: "romance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/h1i78T0.jpg", name: "romance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ccsLqqm.jpg", name: "romance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/hhPEFZY.jpg", name: "romance", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/JByKrIq.jpg", name: "romance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/WzGBvVD.jpg", name: "romance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/k9nBsJq.jpg", name: "romance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/023vWTR.jpg", name: "romance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/SptlUNZ.jpg", name: "romance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/4YkJQ73.jpg", name: "romance", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Zp3QygU.jpg", name: "romance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/uNDR6OJ.jpg", name: "romance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ZuWwIAW.jpg", name: "romance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/0vNbO7I.jpg", name: "romance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/m5yAl1Q.jpg", name: "romance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/GknoChg.jpg", name: "romance", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/GacqnmO.jpg", name: "romance", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/7cJaLLn.jpg", name: "romance", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/LtiqUKd.jpg", name: "romance", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/PWKw0Ak.jpg", name: "romance", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/1wcW2fr.jpg", name: "romance", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/k3uCaDs.jpg", name: "romance", chara: "seth", category: "normal"},
    // santa
        {image: "https://i.imgur.com/SYPnCHc.jpg", name: "santa", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/53sJJ3k.jpg", name: "santa", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/gvFRRoe.jpg", name: "santa", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/mFtGkDp.jpg", name: "santa", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/iHpP2Sz.jpg", name: "santa", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/0Oxwax5.jpg", name: "santa", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/qgZ33mE.jpg", name: "santa", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/JvbvadQ.jpg", name: "santa", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/kXKIp5F.jpg", name: "santa", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/klJ9WMR.jpg", name: "santa", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Eb1fFla.jpg", name: "santa", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/kljpADo.jpg", name: "santa", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/c22B3Rg.jpg", name: "santa", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/U2aDcdC.jpg", name: "santa", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/LuL8czA.jpg", name: "santa", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Ki0nL1e.jpg", name: "santa", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/3e6KFZD.jpg", name: "santa", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/yJXQxm7.jpg", name: "santa", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/nrdQNXd.jpg", name: "santa", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/41q13f2.jpg", name: "santa", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/yJ0svJB.jpg", name: "santa", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/uJv0C3P.jpg", name: "santa", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/CIUHqpT.jpg", name: "santa", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/bGJRrSV.jpg", name: "santa", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/q2m0q1u.jpg", name: "santa", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/8Aj8psF.jpg", name: "santa", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/CSbJgnG.jpg", name: "santa", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Sx7kVp2.jpg", name: "santa", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/938e7tD.jpg", name: "santa", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/TxJIqif.jpg", name: "santa", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/gxXJZBx.jpg", name: "santa", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/ZUzo6nr.jpg", name: "santa", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/YLYmdP6.jpg", name: "santa", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/6R8R8fO.jpg", name: "santa", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/9PLEMUn.jpg", name: "santa", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/CsSg9g5.jpg", name: "santa", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/6qgIVq5.jpg", name: "santa", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UBzE43a.jpg", name: "santa", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Y2aAdZX.jpg", name: "santa", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/i5bzlle.jpg", name: "santa", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/8xbHWxf.jpg", name: "santa", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Q56cl5u.jpg", name: "santa", chara: "bai", category: "normal"},
    // serving
        {image: "https://i.imgur.com/WQR2tb0.jpg", name: "serving", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/tdIVSaB.jpg", name: "serving", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/g7x1Hy1.jpg", name: "serving", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/bHeDRwW.jpg", name: "serving", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Br4MVId.jpg", name: "serving", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/rbu7Aj8.jpg", name: "serving", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/15YbtMA.jpg", name: "serving", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/reXsqMP.jpg", name: "serving", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Rh5ZxcP.jpg", name: "serving", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/xOhfd6H.jpg", name: "serving", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/87xHmsc.jpg", name: "serving", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/qL2FZ5k.jpg", name: "serving", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/dg1TC0v.jpg", name: "serving", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/3rZDas2.jpg", name: "serving", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/x58btUs.jpg", name: "serving", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/6Gwkw25.png", name: "serving", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/V3fdvXk.png", name: "serving", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/zpu9h7v.png", name: "serving", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/OOseLmY.png", name: "serving", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/AKvF10N.png", name: "serving", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/wAamuZK.jpg", name: "serving", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/BwcyQE3.jpg", name: "serving", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/hHkY8ZS.jpg", name: "serving", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/XwFWQje.jpg", name: "serving", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/yDvWt14.jpg", name: "serving", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/c5fyBoU.jpg", name: "serving", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/sxBBvZM.jpg", name: "serving", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/pqAwsZU.jpg", name: "serving", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/krYaxEc.jpg", name: "serving", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/mfCOqJV.jpg", name: "serving", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/6RIflDx.jpg", name: "serving", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/vnjQx6i.jpg", name: "serving", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/ABE3AHy.jpg", name: "serving", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/hW3rOxd.jpg", name: "serving", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/0R00T4q.jpg", name: "serving", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/tJtpd3y.png", name: "serving", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/O9eitu9.png", name: "serving", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/h5NkwPn.png", name: "serving", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/AgUY5vc.png", name: "serving", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/wSgTENq.png", name: "serving", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/bABglFM.jpg", name: "serving", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/6FP8IzE.jpg", name: "serving", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/VR87bHK.jpg", name: "serving", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/gDY10dL.jpg", name: "serving", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/NxyRo71.jpg", name: "serving", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/zmVNXP0.jpg", name: "serving", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/9RClg9g.jpg", name: "serving", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/9fP2HJG.jpg", name: "serving", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/6cKVfVP.jpg", name: "serving", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/o0mHii4.jpg", name: "serving", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/i04EloB.jpg", name: "serving", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/KCgQfZ6.jpg", name: "serving", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/KDFdLHB.jpg", name: "serving", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/U7BxIJY.jpg", name: "serving", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/2WCEXPV.jpg", name: "serving", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/ZA4yVjn.png", name: "serving", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/z4hn69M.png", name: "serving", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/rboQInZ.png", name: "serving", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/5GmxTcq.png", name: "serving", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/WTaJGq5.png", name: "serving", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/TOnOGAj.jpg", name: "serving", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/6IrfWxe.jpg", name: "serving", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/p10a2sV.jpg", name: "serving", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Ljbutqf.jpg", name: "serving", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/6vLfAqK.jpg", name: "serving", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ME62iYR.jpg", name: "serving", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/ga2TYdO.jpg", name: "serving", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/VFN7A7b.jpg", name: "serving", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/kb9J8jR.jpg", name: "serving", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/f3DIe2d.jpg", name: "serving", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Z9v4MRB.jpg", name: "serving", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/KzOdMUV.jpg", name: "serving", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/fjT9pmH.jpg", name: "serving", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/oFCuFmJ.jpg", name: "serving", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/qtwGveP.jpg", name: "serving", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/V4wjg2W.png", name: "serving", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/4KN2kWH.png", name: "serving", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/YoZgf8v.png", name: "serving", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/z4XNxk4.png", name: "serving", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/uzTUSuT.png", name: "serving", chara: "aeri", category: "normal"},
    // SnowFox
        {image: "https://i.imgur.com/tIWking.png", name: "snowfox", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/iikFjY2.png", name: "snowfox", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/fB3UL0n.png", name: "snowfox", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/3QwfR69.png", name: "snowfox", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/btMv1Jw.png", name: "snowfox", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/BxKA5wP.png", name: "snowfox", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/LVNB2np.png", name: "snowfox", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/7FzrvH5.png", name: "snowfox", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Cuo7GAI.png", name: "snowfox", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/aKz5qdX.png", name: "snowfox", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/HR0On9f.jpg", name: "snowfox", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/QLBLh8S.jpg", name: "snowfox", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/3WWZ3yL.jpg", name: "snowfox", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/7kRz5iW.jpg", name: "snowfox", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/fZHKsKH.jpg", name: "snowfox", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/BjBX7gG.png", name: "snowfox", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/tTCRHiM.png", name: "snowfox", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/ibzE4x8.png", name: "snowfox", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/tQFBnJI.png", name: "snowfox", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/CRRcQG5.png", name: "snowfox", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/WPlta1l.png", name: "snowfox", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/xbKHYVc.png", name: "snowfox", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/WC5aQLH.png", name: "snowfox", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Ji0cksG.png", name: "snowfox", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/nI2zfGt.png", name: "snowfox", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/M0pE9rk.png", name: "snowfox", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/V88bzfl.png", name: "snowfox", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/gimMRjN.png", name: "snowfox", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/4x9AZIP.png", name: "snowfox", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/2lvAPV8.png", name: "snowfox", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/FTd0mAI.jpg", name: "snowfox", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/UF9kw29.jpg", name: "snowfox", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/kdGGe1c.jpg", name: "snowfox", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/jhn175z.jpg", name: "snowfox", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/aQwggLp.jpg", name: "snowfox", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/pD4DcRr.png", name: "snowfox", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/82TvJ48.png", name: "snowfox", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/zB0Q8At.png", name: "snowfox", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/CHOVytK.png", name: "snowfox", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/tjQsTSW.png", name: "snowfox", chara: "aeri", category: "normal"},
    // space
        {image: "https://i.imgur.com/4PQ16HH.jpg", name: "space", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Imr386n.jpg", name: "space", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/F1BVSj5.jpg", name: "space", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/4KqVf8R.jpg", name: "space", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/y9eauZs.jpg", name: "space", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/dVN4K7q.jpg", name: "space", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/tQQbkDM.jpg", name: "space", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/s53Cxm5.jpg", name: "space", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/r0sxCh5.jpg", name: "space", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/demLZBN.jpg", name: "space", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/cHW2cyO.jpg", name: "space", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/4EtNiw9.jpg", name: "space", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/iVu0R4K.jpg", name: "space", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/OnfNsCP.jpg", name: "space", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/WRgdf7h.jpg", name: "space", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/v5vnKWn.jpg", name: "space", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/xwX52FB.jpg", name: "space", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/KLfVOrM.jpg", name: "space", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/2sfezn7.jpg", name: "space", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/owpnpDe.jpg", name: "space", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/lcQ0TPA.jpg", name: "space", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/JDEQuzc.jpg", name: "space", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/v060QRV.jpg", name: "space", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/WuqAQer.jpg", name: "space", chara: "eunha", category: "normal"},
    // starlight
        {image: "https://i.imgur.com/8vRtMRH.jpg", name: "starlight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/c71uuCq.jpg", name: "starlight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/3iSMfLI.jpg", name: "starlight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/QTo9QVH.jpg", name: "starlight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/eTidlr6.jpg", name: "starlight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ZTKtDly.jpg", name: "starlight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/fBRp93A.jpg", name: "starlight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/mCpBAog.jpg", name: "starlight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/zqDIUfu.jpg", name: "starlight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/a0mbO4h.jpg", name: "starlight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/8krYHWu.jpg", name: "starlight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/oVGUf54.jpg", name: "starlight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/mBjOAO3.jpg", name: "starlight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Cobu6hT.jpg", name: "starlight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/k7PfyEI.jpg", name: "starlight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/F4Enwxu.jpg", name: "starlight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/SPUCm1e.jpg", name: "starlight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/baKjRPE.jpg", name: "starlight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/kf3JGRs.jpg", name: "starlight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/PpUbHQ3.jpg", name: "starlight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/y0Vj17o.jpg", name: "starlight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/jzqEdaQ.jpg", name: "starlight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/yU3rcP4.jpg", name: "starlight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/uvtnaw3.jpg", name: "starlight", chara: "eunha", category: "normal"},
    // sweetpunk
        {image: "https://i.imgur.com/aufAMj5.jpg", name: "sweetpunk", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/HD3rhjs.jpg", name: "sweetpunk", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/WR5ylkO.jpg", name: "sweetpunk", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/6UhIPsR.jpg", name: "sweetpunk", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/oguBB5G.jpg", name: "sweetpunk", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/5Vs2OVh.jpg", name: "sweetpunk", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/99tPUIw.jpg", name: "sweetpunk", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/gU9Ptvj.jpg", name: "sweetpunk", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/DQcqtaP.jpg", name: "sweetpunk", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Dew0JIK.jpg", name: "sweetpunk", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/6HzCOnu.jpg", name: "sweetpunk", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/qhcsBbs.jpg", name: "sweetpunk", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/aFoXDzU.jpg", name: "sweetpunk", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/YqAFXqe.jpg", name: "sweetpunk", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/lkwST8n.jpg", name: "sweetpunk", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/bdbI6Qa.jpg", name: "sweetpunk", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/4O00Igq.jpg", name: "sweetpunk", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/celFGAx.jpg", name: "sweetpunk", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/0fHvjjH.jpg", name: "sweetpunk", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/mWj6jPj.jpg", name: "sweetpunk", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/EPvgjoO.jpg", name: "sweetpunk", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/ZWXiW8a.jpg", name: "sweetpunk", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/q4m1iPB.jpg", name: "sweetpunk", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/jzCGVJA.jpg", name: "sweetpunk", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/W7x7Z8g.jpg", name: "sweetpunk", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/nsIRHfK.jpg", name: "sweetpunk", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/bYDLjom.jpg", name: "sweetpunk", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/JqoMPcq.jpg", name: "sweetpunk", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/aWw66vf.jpg", name: "sweetpunk", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/cCZdKn0.jpg", name: "sweetpunk", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/GAteZFk.jpg", name: "sweetpunk", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/jHCBGdD.jpg", name: "sweetpunk", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/lT3319y.jpg", name: "sweetpunk", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/f1BXbfX.jpg", name: "sweetpunk", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/2J0llBw.jpg", name: "sweetpunk", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/aQbFhOY.jpg", name: "sweetpunk", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/qiRomrJ.jpg", name: "sweetpunk", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Q0qEu65.jpg", name: "sweetpunk", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/abuBSqZ.jpg", name: "sweetpunk", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/YubEPqJ.jpg", name: "sweetpunk", chara: "aeri", category: "normal"},
    // swim16
        {image: "https://i.imgur.com/YmHeq7O.png", name: "swim16", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/Yx5atJf.png", name: "swim16", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/ViYcGNg.png", name: "swim16", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/delNwMg.png", name: "swim16", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/UzzBOqX.png", name: "swim16", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/8NJ7xuc.png", name: "swim16", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/av1fvve.png", name: "swim16", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/0moEbDT.png", name: "swim16", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/FQSMpCa.png", name: "swim16", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/lKvg7WG.png", name: "swim16", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/oefNJgz.png", name: "swim16", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/8KFLbsI.png", name: "swim16", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/DkdVzR9.png", name: "swim16", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/GKkPGzH.png", name: "swim16", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/jbgrdeA.png", name: "swim16", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/Tp3CJ8n.png", name: "swim16", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/EXF2KrU.png", name: "swim16", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/XG5HCDz.png", name: "swim16", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/VvtdGRL.png", name: "swim16", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/XlOk35N.png", name: "swim16", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/zXBCWKN.png", name: "swim16", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/MXs3ewD.png", name: "swim16", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/1twm5S0.png", name: "swim16", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/LtUAGCC.png", name: "swim16", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/fNwJGrD.png", name: "swim16", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/WtslWd8.png", name: "swim16", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/6TK1qg5.png", name: "swim16", chara: "tina", category: "swim"},
    // swim17
        {image: "https://i.imgur.com/olSRe6j.jpg", name: "swim17", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/NPy2lI6.jpg", name: "swim17", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/tYyW7bC.jpg", name: "swim17", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/pirkHxF.jpg", name: "swim17", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/DMb0cKw.jpg", name: "swim17", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/3yJf565.jpg", name: "swim17", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/UXCbW6q.jpg", name: "swim17", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/C2CWZjb.jpg", name: "swim17", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/xIHvPup.jpg", name: "swim17", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/SFfquMX.jpg", name: "swim17", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/YQr6lVw.jpg", name: "swim17", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/yeAnX3m.jpg", name: "swim17", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/4Etxen6.jpg", name: "swim17", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/X2u6JCO.jpg", name: "swim17", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/NmFbk0K.jpg", name: "swim17", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/bxWiIvi.jpg", name: "swim17", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/9u6vUIs.jpg", name: "swim17", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/hOYU06l.jpg", name: "swim17", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/YIvwviw.jpg", name: "swim17", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/ELbuEvQ.jpg", name: "swim17", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/tb7mJrY.jpg", name: "swim17", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/NARDagK.jpg", name: "swim17", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/DYblGdn.jpg", name: "swim17", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/68ji2CX.jpg", name: "swim17", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/9TMeQGW.jpg", name: "swim17", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/3S0MxOo.jpg", name: "swim17", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/YBexx4O.jpg", name: "swim17", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/PJeTT3B.jpg", name: "swim17", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/HVi1vDq.jpg", name: "swim17", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/MGdox0t.jpg", name: "swim17", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/zAf9ZzJ.jpg", name: "swim17", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/mN9FWNV.jpg", name: "swim17", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/2zvRapA.jpg", name: "swim17", chara: "wolfgang", category: "swim"},
    // swim18
        {image: "https://i.imgur.com/T5P8mmB.jpg", name: "swim18", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/1nZtxJS.jpg", name: "swim18", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/8mDutEr.jpg", name: "swim18", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/ucc56yG.jpg", name: "swim18", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/WoF1icm.jpg", name: "swim18", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/BSmdiBd.jpg", name: "swim18", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/xFJbJeF.jpg", name: "swim18", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/brqdrkb.jpg", name: "swim18", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/WRfBg1F.jpg", name: "swim18", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/lO6gZRh.jpg", name: "swim18", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/8bHIVJ6.jpg", name: "swim18", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/gPHPkUE.jpg", name: "swim18", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/j4rYcle.jpg", name: "swim18", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/haiihe0.jpg", name: "swim18", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/KfDi095.jpg", name: "swim18", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/hIneImV.jpg", name: "swim18", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/CvSnrs7.jpg", name: "swim18", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/a7sXnHr.jpg", name: "swim18", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/PKJaLuQ.jpg", name: "swim18", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/nCsTctC.jpg", name: "swim18", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/pVndDri.jpg", name: "swim18", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/VCtATNH.jpg", name: "swim18", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/WVhcDWS.jpg", name: "swim18", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/9LEPCMS.jpg", name: "swim18", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/Fv3EwiH.jpg", name: "swim18", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/lm2bZnl.jpg", name: "swim18", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/rx1gjpS.jpg", name: "swim18", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/jJclC4a.jpg", name: "swim18", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/A1ykIpt.jpg", name: "swim18", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/q85KByS.jpg", name: "swim18", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/EJzDG3W.jpg", name: "swim18", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/uozwvg4.jpg", name: "swim18", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/0CQNLhl.jpg", name: "swim18", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/DZChaC4.jpg", name: "swim18", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/o4Yi6kp.jpg", name: "swim18", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/zwL9VpS.jpg", name: "swim18", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/YZ1Hhd7.jpg", name: "swim18", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/WGDTCj7.jpg", name: "swim18", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/fnJKRP0.jpg", name: "swim18", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/YEHre3C.jpg", name: "swim18", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/L5ESck4.jpg", name: "swim18", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/DJf9f3P.jpg", name: "swim18", chara: "bai", category: "swim"},
    // swim19
        {image: "https://i.imgur.com/Qr6shdh.jpg", name: "swim19", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/HPjSLHT.jpg", name: "swim19", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/DNf86II.jpg", name: "swim19", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/OBQFrca.jpg", name: "swim19", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/AZs11Zi.jpg", name: "swim19", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/B9WYOPO.jpg", name: "swim19", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/z78IbtM.jpg", name: "swim19", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/1x7Rapw.jpg", name: "swim19", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/20C8fVq.jpg", name: "swim19", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/dFL7itm.jpg", name: "swim19", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/0QznnRF.jpg", name: "swim19", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/uK9nnwq.jpg", name: "swim19", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/nqCnU8E.jpg", name: "swim19", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/1uoGbRP.jpg", name: "swim19", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/YjJeGHx.jpg", name: "swim19", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/s2VoEh4.jpg", name: "swim19", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/tYBsCYF.jpg", name: "swim19", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/wNU7aVD.jpg", name: "swim19", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/hbrvMZU.jpg", name: "swim19", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/Jn4SMqx.jpg", name: "swim19", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/8wxuU4r.jpg", name: "swim19", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/PATDYc0.jpg", name: "swim19", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/PUaeZPW.jpg", name: "swim19", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/0RacVuW.jpg", name: "swim19", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/5qIHFJD.jpg", name: "swim19", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/M0og7FN.jpg", name: "swim19", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/WSl3Vf9.jpg", name: "swim19", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/1KNB2VP.jpg", name: "swim19", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/eZKpRGU.jpg", name: "swim19", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/AidybLy.jpg", name: "swim19", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/AXIzT21.jpg", name: "swim19", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/XBvALPK.jpg", name: "swim19", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/22LK5V9.jpg", name: "swim19", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/46ZrAu2.jpg", name: "swim19", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/R3WMxsN.jpg", name: "swim19", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/1FqBf8o.jpg", name: "swim19", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/Y2PdLql.jpg", name: "swim19", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/HR7C7IJ.jpg", name: "swim19", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/KI7Vkn4.jpg", name: "swim19", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/Qdm50Zw.jpg", name: "swim19", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/esyN7hx.jpg", name: "swim19", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/SUWaEU1.jpg", name: "swim19", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/uvCncFw.jpg", name: "swim19", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/u1ZHdMs.jpg", name: "swim19", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/WctKjbp.jpg", name: "swim19", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/xCZigPu.jpg", name: "swim19", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/F7KOJoA.jpg", name: "swim19", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/mxBitxe.jpg", name: "swim19", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/NdQAS0k.jpg", name: "swim19", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/CoMKGgY.jpg", name: "swim19", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/LtPJQjg.jpg", name: "swim19", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/ghbQWlR.jpg", name: "swim19", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/Z7VJRX6.jpg", name: "swim19", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/zC1Sx2L.jpg", name: "swim19", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/VyflZuH.jpg", name: "swim19", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/xxmDTX5.jpg", name: "swim19", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/Kzxdffr.jpg", name: "swim19", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/aKEvPI7.jpg", name: "swim19", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/QrWn4kA.jpg", name: "swim19", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/auORFvl.jpg", name: "swim19", chara: "seth", category: "swim"},
    // swim20 mark
        {image: "https://i.imgur.com/qxgyI2X.gif", name: "swim20", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/C36jxF9.gif", name: "swim20", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/B17G0qV.gif", name: "swim20", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/PTfHmql.gif", name: "swim20", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/qRLkjoD.gif", name: "swim20", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/YQsYABl.gif", name: "swim20", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/pYEO6zP.gif", name: "swim20", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/8QVdDOz.gif", name: "swim20", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/Y1rlDlN.gif", name: "swim20", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/0G1vyzO.gif", name: "swim20", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/R1rmmCc.gif", name: "swim20", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/gkUJsC7.gif", name: "swim20", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/wFSTPzN.gif", name: "swim20", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/bxFg8LF.gif", name: "swim20", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/QTLrIlp.gif", name: "swim20", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/BA0rQES.gif", name: "swim20", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/trC6pUJ.gif", name: "swim20", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/5kxoqIL.gif", name: "swim20", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/rhsLIOM.gif", name: "swim20", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/SU1oxay.gif", name: "swim20", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/p3J3tFZ.gif", name: "swim20", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/m0F2RXm.gif", name: "swim20", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/G92vzPq.gif", name: "swim20", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/y5n1cnQ.gif", name: "swim20", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/4qcMHlS.gif", name: "swim20", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/pc04sLP.gif", name: "swim20", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/ZTwxOps.gif", name: "swim20", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/wbVbtR3.gif", name: "swim20", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/8hgw5lC.gif", name: "swim20", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/7kFmknJ.gif", name: "swim20", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/nXDcNnt.gif", name: "swim20", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/w25xZtu.gif", name: "swim20", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/2iKiUxW.gif", name: "swim20", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/Gn8S3XB.gif", name: "swim20", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/RIhvgNt.gif", name: "swim20", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/k5iENlN.gif", name: "swim20", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/PkxtIlE.gif", name: "swim20", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/pS0l4wb.gif", name: "swim20", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/S2sXP8n.gif", name: "swim20", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/syufZ0o.gif", name: "swim20", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/xUUuMGX.gif", name: "swim20", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/YkOE5T0.gif", name: "swim20", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/P1eZq5i.gif", name: "swim20", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/POyz5Zr.gif", name: "swim20", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/YIDt9C7.gif", name: "swim20", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/3ZEUeuN.gif", name: "swim20", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/UUu24EW.gif", name: "swim20", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/HSgACV5.gif", name: "swim20", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/EkR3OMk.gif", name: "swim20", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/wPEPq8x.gif", name: "swim20", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/BlHhwlA.gif", name: "swim20", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/rJ59SL9.gif", name: "swim20", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/OeuEXei.gif", name: "swim20", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/WMm3eul.gif", name: "swim20", chara: "eunha", category: "swim"},
    // swim21
        {image: "https://i.imgur.com/GJpbghu.jpg", name: "swim21", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/DhI9fPy.jpg", name: "swim21", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/lsloE0V.jpg", name: "swim21", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/057UcXD.jpg", name: "swim21", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/WuLr1ZM.jpg", name: "swim21", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/6EnkZxc.jpg", name: "swim21", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/nwPBZEA.jpg", name: "swim21", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/whXajIO.jpg", name: "swim21", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/1D8TNc5.jpg", name: "swim21", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/oqb6yT2.jpg", name: "swim21", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/UwvTrAO.jpg", name: "swim21", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/5qK3EAD.jpg", name: "swim21", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/BCWstWT.jpg", name: "swim21", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/WTV7q49.jpg", name: "swim21", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/uRumssL.jpg", name: "swim21", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/izmvxP3.jpg", name: "swim21", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/nBiu9gX.jpg", name: "swim21", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/pKYAfxa.jpg", name: "swim21", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/4n0aMUF.jpg", name: "swim21", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/sHFHRNv.jpg", name: "swim21", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/ms4TZYs.jpg", name: "swim21", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/8SIJUAO.jpg", name: "swim21", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/A5vrmrU.jpg", name: "swim21", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/waqCBRJ.jpg", name: "swim21", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/2VMO0HX.jpg", name: "swim21", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/YJ0B3AS.jpg", name: "swim21", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/y7f3mJF.jpg", name: "swim21", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/HqYOvoO.jpg", name: "swim21", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/1Udev2J.jpg", name: "swim21", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/xUImB6J.jpg", name: "swim21", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/wcfWyom.jpg", name: "swim21", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/OezKsGq.jpg", name: "swim21", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/pFKWGNo.jpg", name: "swim21", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/2zGvgqV.jpg", name: "swim21", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/u8ipFcy.jpg", name: "swim21", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/fwqZfSq.jpg", name: "swim21", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/RXVolUl.jpg", name: "swim21", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/5wsJlRk.jpg", name: "swim21", chara: "lucy", category: "swim"},
    // swim22
        {image: "https://i.imgur.com/EyDoNPO.png", name: "swim22", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/ZdRPxLM.png", name: "swim22", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/DkxhDdn.png", name: "swim22", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/oRjiXwl.png", name: "swim22", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/Drgh872.png", name: "swim22", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/YYYelPc.png", name: "swim22", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/vVMCMu2.png", name: "swim22", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/T5TOmtU.png", name: "swim22", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/Rqi6BE6.png", name: "swim22", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/UVCgTVF.png", name: "swim22", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/sGp4BzL.png", name: "swim22", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/iZkBVCJ.png", name: "swim22", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/FUn8QmE.png", name: "swim22", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/74fFV0I.png", name: "swim22", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/xMvSSQa.png", name: "swim22", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/TkhVhrY.png", name: "swim22", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/QFUugOB.png", name: "swim22", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/DJ5HmQF.png", name: "swim22", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/uZdXpzz.png", name: "swim22", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/nTa9DBs.png", name: "swim22", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/vhmUALE.png", name: "swim22", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/Z2x3uyO.png", name: "swim22", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/rNS2jfR.png", name: "swim22", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/N0gntMW.png", name: "swim22", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/xcqwB9n.png", name: "swim22", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/F4LF4SX.png", name: "swim22", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/v8ljnQq.png", name: "swim22", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/QJKq3bL.png", name: "swim22", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/LGOt2O6.png", name: "swim22", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/RAH4VAt.png", name: "swim22", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/uWaJ1W1.png", name: "swim22", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/ajRSgx9.png", name: "swim22", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/jma89xV.png", name: "swim22", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/Lybin0R.png", name: "swim22", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/HpDXE7t.png", name: "swim22", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/fNVzO7u.png", name: "swim22", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/ppgB61Q.png", name: "swim22", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/W24ka9V.png", name: "swim22", chara: "lucy", category: "swim"},
    // swim23
        {image: "https://i.imgur.com/ri1Rz32.png", name: "swim23", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/mOqrpDq.png", name: "swim23", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/ek8cfDS.png", name: "swim23", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/Gya49ju.png", name: "swim23", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/NwrIbb3.png", name: "swim23", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/8b1EyAz.png", name: "swim23", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/d76UzvF.png", name: "swim23", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/LXnQuZl.png", name: "swim23", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/mFAutSa.png", name: "swim23", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/wm8zn1r.png", name: "swim23", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/Oc4ftzF.png", name: "swim23", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/xfKCNa6.png", name: "swim23", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/Qh9oThy.png", name: "swim23", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/Ap0Csbc.png", name: "swim23", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/zLzIOwt.png", name: "swim23", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/b0tPyqs.png", name: "swim23", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/rdnDH7s.png", name: "swim23", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/WuPz0gO.png", name: "swim23", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/PNWeMaO.png", name: "swim23", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/YFcvpm9.png", name: "swim23", chara: "aeri", category: "swim"},
        {image: "https://i.imgur.com/fzKKWtK.png", name: "swim23", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/1eUPtpT.png", name: "swim23", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/28dNhrA.png", name: "swim23", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/Yx1kQRn.png", name: "swim23", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/YhxUqzU.png", name: "swim23", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/sBaViEM.png", name: "swim23", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/GjmmlhK.png", name: "swim23", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/ANLPwIW.png", name: "swim23", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/NdPiEwZ.png", name: "swim23", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/3VNDw7i.png", name: "swim23", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/DtbPeif.png", name: "swim23", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/idtm8xE.png", name: "swim23", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/6zuR2Th.png", name: "swim23", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/Sgt2Av5.png", name: "swim23", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/cqrYYZM.png", name: "swim23", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/Ktfcej4.png", name: "swim23", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/cB3VVbM.png", name: "swim23", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/fZOzMex.png", name: "swim23", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/T6pycrC.png", name: "swim23", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/sb75ys8.png", name: "swim23", chara: "aeri", category: "swim"},
    // Track_Shooter
        {image: "https://i.imgur.com/Y5yP5XP.jpg", name: "track_shooter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/as2Fzaj.jpg", name: "track_shooter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/LWQts7a.jpg", name: "track_shooter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/2i1NRNn.jpg", name: "track_shooter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/t5oXDt4.jpg", name: "track_shooter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/ov4RbQh.jpg", name: "track_shooter", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/LSc3QxK.jpg", name: "track_shooter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/hjlsoXx.jpg", name: "track_shooter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/jQd5FhB.jpg", name: "track_shooter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Q559goq.jpg", name: "track_shooter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/6XFygIG.jpg", name: "track_shooter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/0jul48u.jpg", name: "track_shooter", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/3LwIzjG.jpg", name: "track_shooter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/jPRIvHl.jpg", name: "track_shooter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/f7k6VWi.jpg", name: "track_shooter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/2n5uUGp.jpg", name: "track_shooter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/tk9wvqV.jpg", name: "track_shooter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/FjNUQcf.jpg", name: "track_shooter", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/6r0WcAE.jpg", name: "track_shooter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/cUDF6eM.jpg", name: "track_shooter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/YrS6mZS.jpg", name: "track_shooter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/N1gcrDr.jpg", name: "track_shooter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/hFlhkH9.jpg", name: "track_shooter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/SysqDTE.jpg", name: "track_shooter", chara: "chulsoo", category: "normal"},
    // union_secret
        {image: "https://i.imgur.com/GwF1ftu.jpg", name: "union_secret", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/gbxm2oL.jpg", name: "union_secret", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/HehiMy3.jpg", name: "union_secret", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/gnajgqF.jpg", name: "union_secret", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/ukbBnqG.jpg", name: "union_secret", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/c1yUi0E.jpg", name: "union_secret", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ORcLVAr.jpg", name: "union_secret", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/3byLZgP.jpg", name: "union_secret", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/5aR7NJo.jpg", name: "union_secret", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/1VJbdux.jpg", name: "union_secret", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/wpxPTbE.jpg", name: "union_secret", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/0rzu6rj.jpg", name: "union_secret", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/mBixp6r.jpg", name: "union_secret", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/yJ4lweC.jpg", name: "union_secret", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/VXrT23b.jpg", name: "union_secret", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/u4brLcI.jpg", name: "union_secret", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/x0hNg1i.jpg", name: "union_secret", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/lpGCpIU.jpg", name: "union_secret", chara: "soma", category: "normal"},
        // 2nd release
        {image: "https://i.imgur.com/hb9VkYY.jpg", name: "union_secret", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/D9SaG6l.jpg", name: "union_secret", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/abJiLl8.jpg", name: "union_secret", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/VysXF71.jpg", name: "union_secret", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/mYJh37u.jpg", name: "union_secret", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/hbrvb5b.jpg", name: "union_secret", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/ovUBHPH.jpg", name: "union_secret", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/f2hci4m.jpg", name: "union_secret", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/th2jtKb.jpg", name: "union_secret", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/wSjDqpa.jpg", name: "union_secret", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/SrOHbb1.jpg", name: "union_secret", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/vCoiyEC.jpg", name: "union_secret", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/eHqrdkg.jpg", name: "union_secret", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/LBs8lpI.jpg", name: "union_secret", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/2dyGWos.jpg", name: "union_secret", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/AJiFnDX.jpg", name: "union_secret", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/L5a53Nb.jpg", name: "union_secret", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/hhbKy5u.jpg", name: "union_secret", chara: "aeri", category: "normal"},
    // urban
        {image: "https://i.imgur.com/YPemAj4.jpg", name: "urban", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/hh9xw6Q.jpg", name: "urban", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/PcLeX1D.jpg", name: "urban", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/wJoqi8O.jpg", name: "urban", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/rycaCgS.jpg", name: "urban", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/qxuwGLv.jpg", name: "urban", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/oaIU84T.jpg", name: "urban", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Z2DS2KA.jpg", name: "urban", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/Trjbp1m.jpg", name: "urban", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/jWEyfLt.jpg", name: "urban", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/GPC3UBS.jpg", name: "urban", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/t9Ora1e.jpg", name: "urban", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/60NeKkH.jpg", name: "urban", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/35pRP4b.jpg", name: "urban", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/eYJKd21.jpg", name: "urban", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/fYXOYbM.jpg", name: "urban", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/4dEeRTc.jpg", name: "urban", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/1yVzIyr.jpg", name: "urban", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ILF0FUC.jpg", name: "urban", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/dDGLqFJ.png", name: "urban", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/VEymm5y.jpg", name: "urban", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/1017AHb.jpg", name: "urban", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/rSISe75.jpg", name: "urban", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/P4fH8W4.jpg", name: "urban", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/fMv9qan.jpg", name: "urban", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/TRPSQdN.jpg", name: "urban", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/kJqMfu5.jpg", name: "urban", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/kRN0PLM.jpg", name: "urban", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/bjznf1w.jpg", name: "urban", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Xs5XvoF.jpg", name: "urban", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/QyuQCif.jpg", name: "urban", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/G46BvwM.jpg", name: "urban", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/Y7kjN1x.jpg", name: "urban", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/wrvllmp.jpg", name: "urban", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/xSx7CjX.jpg", name: "urban", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/fAvvSTu.jpg", name: "urban", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/rLHtwrR.jpg", name: "urban", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/m94DJZD.jpg", name: "urban", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/MOvKXOW.jpg", name: "urban", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/KOlmUhB.png", name: "urban", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/PCMVGNa.jpg", name: "urban", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/PIW5M4q.jpg", name: "urban", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/3rJURMj.jpg", name: "urban", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/SASOLVK.jpg", name: "urban", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/te7e2Rh.jpg", name: "urban", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/ZU6AP2Z.jpg", name: "urban", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/gANfrdb.jpg", name: "urban", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/0CXaZfZ.jpg", name: "urban", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/jzplnK0.jpg", name: "urban", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/2td73gw.jpg", name: "urban", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Cdq4sZH.jpg", name: "urban", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/KE8QczL.jpg", name: "urban", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/9eFFE9e.jpg", name: "urban", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/rxxcgqQ.jpg", name: "urban", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/CutdJUC.jpg", name: "urban", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/E2h25nI.jpg", name: "urban", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/nEntGdH.jpg", name: "urban", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Ny46L49.jpg", name: "urban", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/rdt4sQB.jpg", name: "urban", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/mNuqvYE.png", name: "urban", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/NgofkvV.jpg", name: "urban", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/WUWDoiK.jpg", name: "urban", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/RFFk0w2.jpg", name: "urban", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/ASEqm7O.jpg", name: "urban", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/CF1gcjb.jpg", name: "urban", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/vNWt9eh.jpg", name: "urban", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/qdyVYjL.jpg", name: "urban", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/7ARNk1D.jpg", name: "urban", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/5Xoym86.jpg", name: "urban", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/cWlzDTP.jpg", name: "urban", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/WXCcMnw.jpg", name: "urban", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/jz4LK29.jpg", name: "urban", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/CfVkFUW.jpg", name: "urban", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/K2EbNzD.jpg", name: "urban", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/2cLyLMC.jpg", name: "urban", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/WItr5Mq.jpg", name: "urban", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/qqh9Tm7.jpg", name: "urban", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/Z2pmfhM.jpg", name: "urban", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/SAiMcfv.jpg", name: "urban", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/gd73kqF.png", name: "urban", chara: "aeri", category: "normal"},
    // wedding
        {image: "https://i.imgur.com/B0D3q6T.jpg", name: "wedding", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Ll7yl76.jpg", name: "wedding", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/rEFZU18.jpg", name: "wedding", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/PWce2VH.jpg", name: "wedding", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/WDkD8Gt.jpg", name: "wedding", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/HJkOY5a.jpg", name: "wedding", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/BKrPwE3.jpg", name: "wedding", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/bXNMm2j.jpg", name: "wedding", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/S1uS0yZ.jpg", name: "wedding", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/yMsw0gm.jpg", name: "wedding", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/YJ1bXeW.jpg", name: "wedding", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/EvglrIP.jpg", name: "wedding", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/yfV1JGq.jpg", name: "wedding", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/ER6iMzz.jpg", name: "wedding", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/1EoBecK.jpg", name: "wedding", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/biGpGJb.jpg", name: "wedding", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/Z3MVfp9.jpg", name: "wedding", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/JvVrZ9L.jpg", name: "wedding", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/EQGYXyL.jpg", name: "wedding", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/GltQPk4.jpg", name: "wedding", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/RO1hRDn.jpg", name: "wedding", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/wo9bduP.jpg", name: "wedding", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/uytMi27.jpg", name: "wedding", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/vU7qeHp.jpg", name: "wedding", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/e454yk8.jpg", name: "wedding", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/wyP5PqW.jpg", name: "wedding", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Vmc6AF0.jpg", name: "wedding", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/1HdYT9K.jpg", name: "wedding", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/UEkJ2R0.jpg", name: "wedding", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/xUer4kh.jpg", name: "wedding", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/UWgi8uV.jpg", name: "wedding", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/SoAxEuF.jpg", name: "wedding", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/2wPp0cS.jpg", name: "wedding", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Zo94wjL.jpg", name: "wedding", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/V85llbF.jpg", name: "wedding", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/SaU6v7z.jpg", name: "wedding", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/YCCy8vH.jpg", name: "wedding", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/p8zCwm3.jpg", name: "wedding", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/HoZtCQM.jpg", name: "wedding", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/9J8xn1j.jpg", name: "wedding", chara: "aeri", category: "normal"},
    // wildowntown
        {image: "https://i.imgur.com/HhMWC8E.jpg", name: "wildowntown", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/2J5Z8CW.jpg", name: "wildowntown", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/lmIGBxf.jpg", name: "wildowntown", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/J3gUAx2.jpg", name: "wildowntown", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/uXIabOf.jpg", name: "wildowntown", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/A6OUtWv.jpg", name: "wildowntown", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/c1WawKq.jpg", name: "wildowntown", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/ALs0Kqf.jpg", name: "wildowntown", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/fr6IQ4x.jpg", name: "wildowntown", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/OXrV4lW.jpg", name: "wildowntown", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/g6fBDzy.jpg", name: "wildowntown", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/vqz5PmD.jpg", name: "wildowntown", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/MHqcoqK.jpg", name: "wildowntown", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Ljz68vb.jpg", name: "wildowntown", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/q8Q535C.jpg", name: "wildowntown", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/9PMZZ0J.jpg", name: "wildowntown", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/eyLl6Up.jpg", name: "wildowntown", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/eO4V6yn.jpg", name: "wildowntown", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/9lVFrM5.jpg", name: "wildowntown", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/KW3hNn4.jpg", name: "wildowntown", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/KWxw4JC.jpg", name: "wildowntown", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/vEogfmX.jpg", name: "wildowntown", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/B0rcBgG.jpg", name: "wildowntown", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/44ktE6g.jpg", name: "wildowntown", chara: "mirae", category: "normal"},
    // wind
        {image: "https://i.imgur.com/L7QyauD.jpg", name: "wind", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/nyT8d41.jpg", name: "wind", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/HZ3YRmQ.jpg", name: "wind", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/QXLnxK4.jpg", name: "wind", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/GbqNu6d.jpg", name: "wind", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Jj6mYKn.jpg", name: "wind", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/kuTtsog.jpg", name: "wind", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/B7h4p7A.jpg", name: "wind", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/HjiTxT2.jpg", name: "wind", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/EfS7MuT.jpg", name: "wind", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/7K5ac3T.jpg", name: "wind", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/rKssyYN.jpg", name: "wind", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/WrOD20r.jpg", name: "wind", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/25SwNpx.jpg", name: "wind", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/VZtIfUl.jpg", name: "wind", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/yo3E81J.jpg", name: "wind", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/goktGUI.jpg", name: "wind", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/ELyD0Cv.jpg", name: "wind", chara: "bai", category: "normal"},
    // wind_elf
        {image: "https://i.imgur.com/xNM1oiu.jpg", name: "wind_elf", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/ffLQLeH.jpg", name: "wind_elf", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Kf9vZUw.jpg", name: "wind_elf", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/yHbkmwv.jpg", name: "wind_elf", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/vs9wpW1.jpg", name: "wind_elf", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/SInav6Q.jpg", name: "wind_elf", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Fh8gPEf.jpg", name: "wind_elf", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/kIwSNQH.jpg", name: "wind_elf", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/z7ZFYgu.jpg", name: "wind_elf", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/kCo6GiT.jpg", name: "wind_elf", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/dfiXv8y.jpg", name: "wind_elf", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/9iGVjMO.jpg", name: "wind_elf", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/vBM55Mf.jpg", name: "wind_elf", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/5RyK8ZU.jpg", name: "wind_elf", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/ubjHVMu.jpg", name: "wind_elf", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/DRni3hL.jpg", name: "wind_elf", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/A5PeMLR.jpg", name: "wind_elf", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/1a3vdqa.jpg", name: "wind_elf", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/Em8Vt0H.jpg", name: "wind_elf", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/DhV3dZt.jpg", name: "wind_elf", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/2ghn0Kv.jpg", name: "wind_elf", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/nu3qnRf.jpg", name: "wind_elf", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/46ShqoF.jpg", name: "wind_elf", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Xf1oyc5.jpg", name: "wind_elf", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/uUtzIpm.jpg", name: "wind_elf", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/AOearMr.jpg", name: "wind_elf", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/5ysH8PM.jpg", name: "wind_elf", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/z18Urer.jpg", name: "wind_elf", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/nXKrPcy.jpg", name: "wind_elf", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/2C3o7Ez.jpg", name: "wind_elf", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/MASDXHA.jpg", name: "wind_elf", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/LrrQvxk.jpg", name: "wind_elf", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ZGF9g8n.jpg", name: "wind_elf", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/dLHbTRK.jpg", name: "wind_elf", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/00deonB.jpg", name: "wind_elf", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/gCXHdHK.jpg", name: "wind_elf", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/XXmXABA.jpg", name: "wind_elf", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/RM4I8Ms.jpg", name: "wind_elf", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/C3KUmft.jpg", name: "wind_elf", chara: "lucy", category: "normal"},
    // winter
        {image: "https://i.imgur.com/rWKiiKF.jpg", name: "winter", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/rXENoPh.jpg", name: "winter", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/0pk96qa.jpg", name: "winter", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/AWT5iwI.jpg", name: "winter", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/Dle2bzJ.jpg", name: "winter", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/9PpxHOd.jpg", name: "winter", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/0T3YClq.jpg", name: "winter", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/HuoQxQs.jpg", name: "winter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/pp2cvfD.jpg", name: "winter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/xLG34ql.jpg", name: "winter", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/poSwmOo.jpg", name: "winter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/mR9L95B.jpg", name: "winter", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/KprVuuP.jpg", name: "winter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/DEaffyk.jpg", name: "winter", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/sjuK95O.jpg", name: "winter", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/qCwWjht.jpg", name: "winter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/fP43uAw.jpg", name: "winter", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/YAbjdRb.jpg", name: "winter", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/BKw1scR.jpg", name: "winter", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/IZb4sSN.jpg", name: "winter", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/n0C9NtH.jpg", name: "winter", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/kgjj5sT.jpg", name: "winter", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/43YLXTm.jpg", name: "winter", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/fxOo74m.jpg", name: "winter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/a2RnrAH.jpg", name: "winter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/UoF6ltP.jpg", name: "winter", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/p44CcWB.jpg", name: "winter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/imaTH5k.jpg", name: "winter", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/sYWhkgA.jpg", name: "winter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Y5cIam8.jpg", name: "winter", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/7gn2c1N.jpg", name: "winter", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/LngElb9.jpg", name: "winter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/HofuNWM.jpg", name: "winter", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/3s71AXD.jpg", name: "winter", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/O1ffHDf.jpg", name: "winter", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/Z0xytDk.jpg", name: "winter", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/C80KFjX.jpg", name: "winter", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/OjNevCW.jpg", name: "winter", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/yJPE4xm.jpg", name: "winter", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/tCxvC2v.jpg", name: "winter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/AIPldke.jpg", name: "winter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/MzlwUM6.jpg", name: "winter", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/4kl7p7g.jpg", name: "winter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Rxhf13x.jpg", name: "winter", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/zx7he3i.jpg", name: "winter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/MJC9L87.jpg", name: "winter", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/T9aitpi.jpg", name: "winter", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/i8F363j.jpg", name: "winter", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/Fh4974Y.jpg", name: "winter", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/0cSnKDO.jpg", name: "winter", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/M55dfFZ.jpg", name: "winter", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/DRtQp8m.jpg", name: "winter", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/DUdrXj3.jpg", name: "winter", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/djBwykC.jpg", name: "winter", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/QnUMFUu.jpg", name: "winter", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/HXIT7ru.jpg", name: "winter", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/NDcg2yQ.jpg", name: "winter", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/11q4xlW.jpg", name: "winter", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Z2vU5zf.jpg", name: "winter", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/2FCOm5P.jpg", name: "winter", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/NI502lj.jpg", name: "winter", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/fmEDReu.jpg", name: "winter", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/0s20TJE.jpg", name: "winter", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/k6NSbWg.jpg", name: "winter", chara: "mirae", category: "normal"},
    // yoho
        {image: "https://i.imgur.com/yJnWnAq.jpg", name: "yoho", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/jOZusTt.jpg", name: "yoho", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/c8TRvCd.jpg", name: "yoho", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/gix1Xug.jpg", name: "yoho", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/d2tiPCz.jpg", name: "yoho", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/b49vfx7.jpg", name: "yoho", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/bkuOMjg.jpg", name: "yoho", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/R98GIhp.jpg", name: "yoho", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/GUbdHGT.jpg", name: "yoho", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/nPXNUuf.jpg", name: "yoho", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/QzYlo5b.jpg", name: "yoho", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/WKrVNUJ.jpg", name: "yoho", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ud5wljN.jpg", name: "yoho", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/CCwDxDX.jpg", name: "yoho", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/i6NzAQU.jpg", name: "yoho", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/kzHD8mw.jpg", name: "yoho", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/iExsRGb.jpg", name: "yoho", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/EniNOae.jpg", name: "yoho", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/GzC1nK6.jpg", name: "yoho", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/fJeS9G9.jpg", name: "yoho", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Gf1ZMhY.jpg", name: "yoho", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/2zMIn8c.jpg", name: "yoho", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Ve24s0H.jpg", name: "yoho", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/JTBVPIZ.jpg", name: "yoho", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/Y6jgbHJ.jpg", name: "yoho", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/trZgNoU.jpg", name: "yoho", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/WYapy9D.jpg", name: "yoho", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/yKLLBn7.jpg", name: "yoho", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/fuaXVnu.jpg", name: "yoho", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/1974eao.jpg", name: "yoho", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/pn48emS.jpg", name: "yoho", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/tTQ7cC9.jpg", name: "yoho", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/M5IsXYH.jpg", name: "yoho", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/kPBKIYk.jpg", name: "yoho", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/WZaNE2Q.jpg", name: "yoho", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/LcwTTwX.jpg", name: "yoho", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/rrGmmE8.jpg", name: "yoho", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/4RLjIub.jpg", name: "yoho", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/9eMWJ5J.jpg", name: "yoho", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/jjNiqht.jpg", name: "yoho", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ePKrNC5.jpg", name: "yoho", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/s5cvOyt.jpg", name: "yoho", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/Ysn36aK.jpg", name: "yoho", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/fL4vYkL.jpg", name: "yoho", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/mWGTfvh.jpg", name: "yoho", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/nu0Bh1y.jpg", name: "yoho", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/lcUEmZa.jpg", name: "yoho", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/iDiOWU2.jpg", name: "yoho", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/CfvjGiO.jpg", name: "yoho", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/M4o3TB5.jpg", name: "yoho", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/EL0j5GR.jpg", name: "yoho", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/vSp7ftY.jpg", name: "yoho", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/qXawRVm.jpg", name: "yoho", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/PP4VLQX.jpg", name: "yoho", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/yg7kbNS.jpg", name: "yoho", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/RyfJXLQ.jpg", name: "yoho", chara: "aeri", category: "normal"},
        
    // moonnight
        {image: "https://i.imgur.com/3DaRFeG.png", name: "moonnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/7nZ3jXb.png", name: "moonnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/Q5RJrSz.png", name: "moonnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/PZj3ZPI.png", name: "moonnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/bajn9Qk.png", name: "moonnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/pof3DEl.png", name: "moonnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/HIXCRR7.png", name: "moonnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/3LP1mzJ.png", name: "moonnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/PIkdjtw.png", name: "moonnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/l9Qcjwk.png", name: "moonnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/LVJ34gC.png", name: "moonnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/oIe5BC0.png", name: "moonnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/jAnbE0G.png", name: "moonnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/3JQs9vp.png", name: "moonnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/M0GKeFY.png", name: "moonnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/994L8jE.png", name: "moonnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/dWkpBKo.png", name: "moonnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/baxdhEv.png", name: "moonnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/Azr7HUF.png", name: "moonnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/VR2Dfyc.png", name: "moonnight", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/4Kt8QvE.png", name: "moonnight", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/PitpIph.png", name: "moonnight", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/diqDlZj.png", name: "moonnight", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/QOxrvt0.png", name: "moonnight", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/lECAmmy.png", name: "moonnight", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/JucZe4s.png", name: "moonnight", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/Qhci2jy.png", name: "moonnight", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/9mDeJz3.png", name: "moonnight", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/JF0xtcg.png", name: "moonnight", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/3FYJDoB.png", name: "moonnight", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/SXS1BpE.png", name: "moonnight", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/HmJR3oh.png", name: "moonnight", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/C4Djiu0.png", name: "moonnight", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/yifyBox.png", name: "moonnight", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/sQQ07Tz.png", name: "moonnight", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/hFMY8Ou.png", name: "moonnight", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/CNbF0Li.png", name: "moonnight", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/x0C1YA4.png", name: "moonnight", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/XJL2Mba.png", name: "moonnight", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/nx9gT2j.png", name: "moonnight", chara: "aeri", category: "normal"},
    // demonicheartbeat
        {image: "https://i.imgur.com/IjlqFnU.jpg", name: "demonicheartbeat", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/NipjejU.jpg", name: "demonicheartbeat", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/aZDJpDu.jpg", name: "demonicheartbeat", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/pRNz3Hj.jpg", name: "demonicheartbeat", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/M9UDLQl.jpg", name: "demonicheartbeat", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/jrNdziS.jpg", name: "demonicheartbeat", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/3wWchHh.jpg", name: "demonicheartbeat", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/28yWxb9.jpg", name: "demonicheartbeat", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/XmFyY6e.jpg", name: "demonicheartbeat", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/dWGubgQ.jpg", name: "demonicheartbeat", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ND8LQWa.jpg", name: "demonicheartbeat", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Dd6c6n4.jpg", name: "demonicheartbeat", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/MrHjJDa.jpg", name: "demonicheartbeat", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/g4AK7aK.jpg", name: "demonicheartbeat", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/MyZbAdf.jpg", name: "demonicheartbeat", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/NTTxMal.jpg", name: "demonicheartbeat", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/R19akpF.jpg", name: "demonicheartbeat", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/AtRR1Nh.jpg", name: "demonicheartbeat", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/HHBJbP6.jpg", name: "demonicheartbeat", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/Q775FAG.jpg", name: "demonicheartbeat", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/eqTWFvs.jpg", name: "demonicheartbeat", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/g29LVWk.jpg", name: "demonicheartbeat", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/iLxcVAk.jpg", name: "demonicheartbeat", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/4Z4Ho5Z.jpg", name: "demonicheartbeat", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/hiKDXhX.jpg", name: "demonicheartbeat", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/Z7su9ww.jpg", name: "demonicheartbeat", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/VNVRVF0.jpg", name: "demonicheartbeat", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/Cr69vKQ.jpg", name: "demonicheartbeat", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/FTMzFBk.jpg", name: "demonicheartbeat", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/W1pL433.jpg", name: "demonicheartbeat", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/Wf4Elfo.jpg", name: "demonicheartbeat", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/CgmBhgc.jpg", name: "demonicheartbeat", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/FCF0oGL.jpg", name: "demonicheartbeat", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Hh1W9KA.jpg", name: "demonicheartbeat", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/YjSuB9y.jpg", name: "demonicheartbeat", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/dklZ2xZ.jpg", name: "demonicheartbeat", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/FQYDXZY.jpg", name: "demonicheartbeat", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/On4HgrC.jpg", name: "demonicheartbeat", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/yYo9qoQ.jpg", name: "demonicheartbeat", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/I2AUs0d.jpg", name: "demonicheartbeat", chara: "aeri", category: "normal"},
    // nobleserenade
        {image: "https://i.imgur.com/eul0QwA.png", name: "nobleserenade", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/qsWfr8E.png", name: "nobleserenade", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/tTXtQb3.jpg", name: "nobleserenade", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/ZTJ9KLc.png", name: "nobleserenade", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/xuPmhns.png", name: "nobleserenade", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/mumib9e.png", name: "nobleserenade", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/7gew6Ll.png", name: "nobleserenade", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/bquHI6N.png", name: "nobleserenade", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/m9u20Cb.png", name: "nobleserenade", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/1B1u2Za.jpg", name: "nobleserenade", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/b9fzYhG.png", name: "nobleserenade", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/7dmZOXD.png", name: "nobleserenade", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/jE090KD.png", name: "nobleserenade", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/vCmhjIx.png", name: "nobleserenade", chara: "lucy", category: "normal"},
    // 台版限定立繪
        {image: "https://i.imgur.com/fR7IglI.png", name: "台版三周年紀念", chara: "seha", category: "limited"},
        {image: "https://i.imgur.com/i2ZIHSU.png", name: "台版三周年紀念", chara: "nata", category: "limited"},
        {image: "https://i.imgur.com/mR6iAYb.png", name: "台版三周年紀念", chara: "luna", category: "limited"},
        {image: "https://i.imgur.com/6e56Od5.png", name: "台版四周年紀念", chara: "mirae", category: "limited"},
        {image: "https://i.imgur.com/F4lszy5.png", name: "台版四周年紀念", chara: "chulsoo", category: "limited"},
        {image: "https://i.imgur.com/8YRHvw1.png", name: "台版四周年紀念", chara: "nata", category: "limited"},
        {image: "https://i.imgur.com/VQuCT9S.png", name: "台版四周年紀念", chara: "tina", category: "limited"},
        {image: "https://i.imgur.com/piq3A6r.png", name: "台版四周年紀念", chara: "j", category: "limited"},
        {image: "https://i.imgur.com/438kkNs.png", name: "台版四周年紀念", chara: "nata", category: "limited"},
        {image: "https://i.imgur.com/wWyA7Tz.png", name: "台版四周年紀念", chara: "tina", category: "limited"},
        {image: "https://i.imgur.com/TzskJcL.png", name: "台版2021聖誕節(聖夜的獄棺姬)", chara: "lucy", category: "limited"},
        {image: "https://i.imgur.com/7ynqpRB.png", name: "台版2022新年(萌虎雙子星)", chara: "seth", category: "limited"},
        {image: "https://i.imgur.com/XEjGz48.jpg", name: "台版五周年紀念(黑色風雲)", chara: "j", category: "limited"},
        {image: "https://i.imgur.com/i682h3c.jpg", name: "台版2022萬聖節(搗蛋之夜)", chara: "levia", category: "limited"},
        {image: "https://i.imgur.com/5lXgKfh.jpg", name: "台版2022萬聖節(搗蛋之夜)", chara: "levia", category: "limited"},
        {image: "https://i.imgur.com/iByVVB4.png", name: "台版2023新年(卯月之花)", chara: "violet", category: "limited"},
    // 愚人節
        {image: "https://i.imgur.com/fXwfGZk.jpg", name: "愚人節立繪", chara: "seha", category: "limited"},
        {image: "https://i.imgur.com/YF1dn75.jpg", name: "愚人節立繪", chara: "seulbi", category: "limited"},
        {image: "https://i.imgur.com/cl6fsSO.jpg", name: "愚人節立繪", chara: "yuri", category: "limited"},
        {image: "https://i.imgur.com/5rmi1My.jpg", name: "愚人節立繪", chara: "j", category: "limited"},
        {image: "https://i.imgur.com/gMuQjWq.jpg", name: "愚人節立繪", chara: "tein", category: "limited"},
        {image: "https://i.imgur.com/JGq7ZZ0.jpg", name: "愚人節立繪", chara: "nata", category: "limited"},
        {image: "https://i.imgur.com/paW4OcY.jpg", name: "愚人節立繪", chara: "levia", category: "limited"},
        {image: "https://i.imgur.com/InePLEP.jpg", name: "愚人節立繪", chara: "harpy", category: "limited"},
        {image: "https://i.imgur.com/KvpSPcn.jpg", name: "愚人節立繪", chara: "tina", category: "limited"},
        {image: "https://i.imgur.com/5n9Tnmv.jpg", name: "愚人節立繪", chara: "violet", category: "limited"},
        {image: "https://i.imgur.com/AMEjsxH.jpg", name: "愚人節立繪", chara: "wolfgang", category: "limited"},
        {image: "https://i.imgur.com/d19Vusu.jpg", name: "愚人節立繪", chara: "luna", category: "limited"},
        {image: "https://i.imgur.com/Bxbb8W1.jpg", name: "愚人節立繪(舊)", chara: "luna", category: "limited"},
        {image: "https://i.imgur.com/9670qVH.jpg", name: "愚人節立繪", chara: "soma", category: "limited"},
        {image: "https://i.imgur.com/OwlwqnZ.jpg", name: "愚人節立繪", chara: "bai", category: "limited"},
        {image: "https://i.imgur.com/51Soe7K.jpg", name: "愚人節立繪", chara: "seth", category: "limited"},
        {image: "https://i.imgur.com/ejOEyRR.jpg", name: "愚人節立繪", chara: "mirae", category: "limited"},
        {image: "https://i.imgur.com/CSg2EWx.jpg", name: "愚人節立繪(舊)", chara: "mirae", category: "limited"},
        {image: "https://i.imgur.com/ZNo6rY3.jpg", name: "愚人節立繪", chara: "chulsoo", category: "limited"},
        {image: "https://i.imgur.com/opziccV.jpg", name: "愚人節立繪(舊)", chara: "chulsoo", category: "limited"},
        {image: "https://i.imgur.com/CDEYcww.jpg", name: "愚人節立繪", chara: "eunha", category: "limited"},
        {image: "https://i.imgur.com/aQ1nLpv.jpg", name: "愚人節立繪", chara: "lucy", category: "limited"},
        {image: "https://i.imgur.com/EbS7OTk.png", name: "愚人節立繪", chara: "aeri", category: "limited"},
        {image: "https://i.imgur.com/g8f7VPw.jpg", name: "愚人節立繪", chara: "seha", category: "limited"},
        {image: "https://i.imgur.com/OKZLOY1.jpg", name: "愚人節立繪", chara: "seulbi", category: "limited"},
        {image: "https://i.imgur.com/QcRCNiL.jpg", name: "愚人節立繪", chara: "yuri", category: "limited"},
        {image: "https://i.imgur.com/QFOJTCt.jpg", name: "愚人節立繪", chara: "j", category: "limited"},
        {image: "https://i.imgur.com/rJ4zZqX.jpg", name: "愚人節立繪", chara: "tein", category: "limited"},
        {image: "https://i.imgur.com/CQRVKPk.jpg", name: "愚人節立繪", chara: "nata", category: "limited"},
        {image: "https://i.imgur.com/GHCFOwl.jpg", name: "愚人節立繪", chara: "levia", category: "limited"},
        {image: "https://i.imgur.com/3Z9VDIp.jpg", name: "愚人節立繪", chara: "harpy", category: "limited"},
        {image: "https://i.imgur.com/lImZT8M.jpg", name: "愚人節立繪", chara: "tina", category: "limited"},
        {image: "https://i.imgur.com/BTLHn88.jpg", name: "愚人節立繪", chara: "violet", category: "limited"},
        {image: "https://i.imgur.com/mh03MmP.jpg", name: "愚人節立繪", chara: "wolfgang", category: "limited"},
        {image: "https://i.imgur.com/ACAVNS0.jpg", name: "愚人節立繪", chara: "luna", category: "limited"},
        {image: "https://i.imgur.com/K9RlZL8.jpg", name: "愚人節立繪", chara: "soma", category: "limited"},
        {image: "https://i.imgur.com/w3z1YUr.jpg", name: "愚人節立繪", chara: "bai", category: "limited"},
        {image: "https://i.imgur.com/LnngEwR.jpg", name: "愚人節立繪", chara: "seth", category: "limited"},
        {image: "https://i.imgur.com/4ShOjBb.jpg", name: "愚人節立繪", chara: "mirae", category: "limited"},
        {image: "https://i.imgur.com/etnSul3.jpg", name: "愚人節立繪", chara: "chulsoo", category: "limited"},
        {image: "https://i.imgur.com/9nSh2gH.jpg", name: "愚人節立繪", chara: "eunha", category: "limited"},
        {image: "https://i.imgur.com/dKG8nlv.jpg", name: "愚人節立繪", chara: "lucy", category: "limited"},
        {image: "https://i.imgur.com/yhgXWNd.png", name: "愚人節立繪", chara: "aeri", category: "limited"},
    // blue_dragon
        {image: "https://i.imgur.com/eEL9Cjq.png", name: "blue_dragon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/Uw8JtXi.png", name: "blue_dragon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/0kI556D.png", name: "blue_dragon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/mUguSxk.png", name: "blue_dragon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/txMizRR.png", name: "blue_dragon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/0UP6wtS.png", name: "blue_dragon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/qojSM5W.png", name: "blue_dragon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/19KHBFP.png", name: "blue_dragon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/ZOAxyBz.png", name: "blue_dragon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/tHJJezS.png", name: "blue_dragon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/NYX5tlL.png", name: "blue_dragon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/kpSyVl9.png", name: "blue_dragon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/FlYpyDa.png", name: "blue_dragon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/QqNFRNw.png", name: "blue_dragon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/GNQVdrL.png", name: "blue_dragon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/B3iURoD.png", name: "blue_dragon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/2zjdU14.png", name: "blue_dragon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/v6txnHE.png", name: "blue_dragon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/nOPzHOv.png", name: "blue_dragon", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/CGXnSXJ.png", name: "blue_dragon", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/5ks8RB9.png", name: "blue_dragon", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/LKGShXf.png", name: "blue_dragon", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/NuOH2bM.png", name: "blue_dragon", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/82HBFwU.png", name: "blue_dragon", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/LotjemK.png", name: "blue_dragon", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/8eAg15k.png", name: "blue_dragon", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/zpryIDz.png", name: "blue_dragon", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/HOqxmGP.png", name: "blue_dragon", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/f7xm4fA.png", name: "blue_dragon", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/1xu9Xqi.png", name: "blue_dragon", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/aoHPfmE.png", name: "blue_dragon", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/tOUErkG.png", name: "blue_dragon", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/9geqK5t.png", name: "blue_dragon", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/b037P2z.png", name: "blue_dragon", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/F8yyd9G.png", name: "blue_dragon", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/svSvckb.png", name: "blue_dragon", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/YDWRf8D.png", name: "blue_dragon", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/p1nopj1.png", name: "blue_dragon", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/hraxur6.png", name: "blue_dragon", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/xC3NGrQ.png", name: "blue_dragon", chara: "aeri", category: "normal"},
    // cherry_blossom
        {image: "https://i.imgur.com/YbTlaFF.jpg", name: "cherry_blossom", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/V1125Wb.jpg", name: "cherry_blossom", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/QeoVFBc.jpg", name: "cherry_blossom", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/l0KdB5T.jpg", name: "cherry_blossom", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/fJl0bJk.jpg", name: "cherry_blossom", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/lHXmb4z.jpg", name: "cherry_blossom", chara: "aeri", category: "normal"},
    // outrage_noir
        {image: "https://i.imgur.com/WVRioQr.png", name: "outrage_noir", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/30U5g9O.png", name: "outrage_noir", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/MEKO1K7.png", name: "outrage_noir", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/SvJ9Bz7.png", name: "outrage_noir", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/10qo8ws.png", name: "outrage_noir", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/b8gUIgZ.png", name: "outrage_noir", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/OACV0fw.png", name: "outrage_noir", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/J7gcM8P.png", name: "outrage_noir", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/FrqII4L.png", name: "outrage_noir", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/bmWhVIM.png", name: "outrage_noir", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/bNLaeLY.png", name: "outrage_noir", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/Wo12Pkl.png", name: "outrage_noir", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/CqSNVeX.png", name: "outrage_noir", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/BZhHdl1.png", name: "outrage_noir", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/m6ZbPEi.png", name: "outrage_noir", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/BQ8ABnb.png", name: "outrage_noir", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/WEkyqP4.png", name: "outrage_noir", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/3YOeRC9.png", name: "outrage_noir", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/wArrK2W.png", name: "outrage_noir", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/1DSmh9c.png", name: "outrage_noir", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/6yj8ukw.png", name: "outrage_noir", chara: "seha", category: "normal"},
        {image: "https://i.imgur.com/GEZzLTl.png", name: "outrage_noir", chara: "seulbi", category: "normal"},
        {image: "https://i.imgur.com/7Jukjl4.png", name: "outrage_noir", chara: "yuri", category: "normal"},
        {image: "https://i.imgur.com/PmyrxKc.png", name: "outrage_noir", chara: "j", category: "normal"},
        {image: "https://i.imgur.com/qlukcEC.png", name: "outrage_noir", chara: "tein", category: "normal"},
        {image: "https://i.imgur.com/Wb72HR9.png", name: "outrage_noir", chara: "nata", category: "normal"},
        {image: "https://i.imgur.com/uqval1V.png", name: "outrage_noir", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/SyUBF0J.png", name: "outrage_noir", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/IEuE0J0.png", name: "outrage_noir", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/uQ5r8ni.png", name: "outrage_noir", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/S0QbqvV.png", name: "outrage_noir", chara: "wolfgang", category: "normal"},
        {image: "https://i.imgur.com/OE7gdAa.png", name: "outrage_noir", chara: "luna", category: "normal"},
        {image: "https://i.imgur.com/vnzLFGt.png", name: "outrage_noir", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/WAT9nlj.png", name: "outrage_noir", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/IgTZsAY.png", name: "outrage_noir", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/WpunQgA.png", name: "outrage_noir", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/fbvfgHS.png", name: "outrage_noir", chara: "chulsoo", category: "normal"},
        {image: "https://i.imgur.com/HuLpSff.png", name: "outrage_noir", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ybFXi28.png", name: "outrage_noir", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/i1LDoAI.png", name: "outrage_noir", chara: "aeri", category: "normal"},    
    // unlucky_chaser
        {image: "https://i.imgur.com/tjK0KBm.png", name: "unlucky_chaser", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/3AZwbMk.png", name: "unlucky_chaser", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/9VN5Nau.png", name: "unlucky_chaser", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/MFBWT5e.png", name: "unlucky_chaser", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/NaLnB5K.png", name: "unlucky_chaser", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/52uD7En.png", name: "unlucky_chaser", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/vadZUtF.png", name: "unlucky_chaser", chara: "aeri", category: "normal"},
        {image: "https://i.imgur.com/0i0cTx0.png", name: "unlucky_chaser", chara: "harpy", category: "normal"},
        {image: "https://i.imgur.com/ZTDM9dP.png", name: "unlucky_chaser", chara: "violet", category: "normal"},
        {image: "https://i.imgur.com/eMUDQMN.png", name: "unlucky_chaser", chara: "soma", category: "normal"},
        {image: "https://i.imgur.com/Imk4kHK.png", name: "unlucky_chaser", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/QoD9xoX.png", name: "unlucky_chaser", chara: "mirae", category: "normal"},
        {image: "https://i.imgur.com/aSM4cUY.png", name: "unlucky_chaser", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/r0Dr4B2.png", name: "unlucky_chaser", chara: "aeri", category: "normal"},
    // love_holic
        {image: "https://i.imgur.com/w6j1dQl.png", name: "love_holic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/AHgxS9W.png", name: "love_holic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/KkH8Xlp.png", name: "love_holic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/WX9qnk8.png", name: "love_holic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/zIEwCKR.png", name: "love_holic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/0zuGFvi.png", name: "love_holic", chara: "lucy", category: "normal"},
        {image: "https://i.imgur.com/JDoE8i0.png", name: "love_holic", chara: "levia", category: "normal"},
        {image: "https://i.imgur.com/bLFOvmf.png", name: "love_holic", chara: "tina", category: "normal"},
        {image: "https://i.imgur.com/swAVMjI.png", name: "love_holic", chara: "bai", category: "normal"},
        {image: "https://i.imgur.com/3sdb2oo.png", name: "love_holic", chara: "seth", category: "normal"},
        {image: "https://i.imgur.com/sqeEh8t.png", name: "love_holic", chara: "eunha", category: "normal"},
        {image: "https://i.imgur.com/ygIPjhJ.png", name: "love_holic", chara: "lucy", category: "normal"},
    // swim24
        {image: "https://i.imgur.com/kFHRT0e.jpg", name: "swim24", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/cg05H9Z.jpg", name: "swim24", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/4SGK9DA.jpg", name: "swim24", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/Wz6CCJs.jpg", name: "swim24", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/q8HGBHG.jpg", name: "swim24", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/hYnLqyi.jpg", name: "swim24", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/t2CNmPs.jpg", name: "swim24", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/2050c90.jpg", name: "swim24", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/pq9M9sM.jpg", name: "swim24", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/yMsJSF6.jpg", name: "swim24", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/Gt5GH0e.jpg", name: "swim24", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/mnF4kxN.jpg", name: "swim24", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/XIUTieJ.jpg", name: "swim24", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/JToLRON.jpg", name: "swim24", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/5rapmQh.jpg", name: "swim24", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/2BnMz8G.jpg", name: "swim24", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/E8udaKb.jpg", name: "swim24", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/kgquQo0.jpg", name: "swim24", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/qCz1cpN.jpg", name: "swim24", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/Pn3mCU7.jpg", name: "swim24", chara: "aeri", category: "swim"},
        {image: "https://i.imgur.com/e4JOKzF.jpg", name: "swim24", chara: "ria", category: "swim"},
        {image: "https://i.imgur.com/o2pigmf.jpg", name: "swim24", chara: "seha", category: "swim"},
        {image: "https://i.imgur.com/LyAYSh5.jpg", name: "swim24", chara: "seulbi", category: "swim"},
        {image: "https://i.imgur.com/19nP99n.jpg", name: "swim24", chara: "yuri", category: "swim"},
        {image: "https://i.imgur.com/S1ZR57Y.jpg", name: "swim24", chara: "j", category: "swim"},
        {image: "https://i.imgur.com/wDziSmC.jpg", name: "swim24", chara: "tein", category: "swim"},
        {image: "https://i.imgur.com/32puJh8.jpg", name: "swim24", chara: "nata", category: "swim"},
        {image: "https://i.imgur.com/z9pokNP.jpg", name: "swim24", chara: "levia", category: "swim"},
        {image: "https://i.imgur.com/BHOJQyJ.jpg", name: "swim24", chara: "harpy", category: "swim"},
        {image: "https://i.imgur.com/N5GOlKN.jpg", name: "swim24", chara: "tina", category: "swim"},
        {image: "https://i.imgur.com/xutkQS0.jpg", name: "swim24", chara: "violet", category: "swim"},
        {image: "https://i.imgur.com/fXbIo01.jpg", name: "swim24", chara: "wolfgang", category: "swim"},
        {image: "https://i.imgur.com/lUV0pc4.jpg", name: "swim24", chara: "luna", category: "swim"},
        {image: "https://i.imgur.com/cv4dtdF.jpg", name: "swim24", chara: "soma", category: "swim"},
        {image: "https://i.imgur.com/Gr4Y1md.jpg", name: "swim24", chara: "bai", category: "swim"},
        {image: "https://i.imgur.com/VTDp23K.jpg", name: "swim24", chara: "seth", category: "swim"},
        {image: "https://i.imgur.com/iU6bZYJ.jpg", name: "swim24", chara: "mirae", category: "swim"},
        {image: "https://i.imgur.com/TbwUIUY.jpg", name: "swim24", chara: "chulsoo", category: "swim"},
        {image: "https://i.imgur.com/1ocRtZf.jpg", name: "swim24", chara: "eunha", category: "swim"},
        {image: "https://i.imgur.com/3ISibWy.jpg", name: "swim24", chara: "lucy", category: "swim"},
        {image: "https://i.imgur.com/BmtVQfk.jpg", name: "swim24", chara: "aeri", category: "swim"},
        {image: "https://i.imgur.com/Xqw5VuZ.jpg", name: "swim24", chara: "ria", category: "swim"},

    ];